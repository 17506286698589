import { ClarityType } from '../constants';
function responseErrorCV(value) {
  return {
    type: ClarityType.ResponseErr,
    value
  };
}
function responseOkCV(value) {
  return {
    type: ClarityType.ResponseOk,
    value
  };
}
export { responseErrorCV, responseOkCV };
