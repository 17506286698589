"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.decode = exports.encode = exports.unescape = exports.escape = exports.pad = void 0;
const base64_js_1 = require("base64-js");
function pad(base64) {
  return `${base64}${'='.repeat(4 - (base64.length % 4 || 4))}`;
}
exports.pad = pad;
function escape(base64) {
  return base64.replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
}
exports.escape = escape;
function unescape(base64Url) {
  return pad(base64Url).replace(/-/g, '+').replace(/_/g, '/');
}
exports.unescape = unescape;
function encode(base64) {
  return escape((0, base64_js_1.fromByteArray)(new TextEncoder().encode(base64)));
}
exports.encode = encode;
function decode(base64Url) {
  return new TextDecoder().decode((0, base64_js_1.toByteArray)(pad(unescape(base64Url))));
}
exports.decode = decode;
