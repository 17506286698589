import { bytesToHex, concatArray, hexToBytes, intToBigInt, intToBytes, writeUInt32BE, writeUInt8 } from '@stacks/common';
import { ClarityType, deserializeCV, noneCV, serializeCV, someCV } from './clarity/';
import { principalCV } from './clarity/types/principalCV';
import { ClarityVersion, COINBASE_BYTES_LENGTH, PayloadType, StacksMessageType, VRF_PROOF_BYTES_LENGTH } from './constants';
import { createAddress, createLPString } from './postcondition-types';
import { codeBodyString, createMemoString, deserializeAddress, deserializeLPString, deserializeMemoString, serializeStacksMessage } from './types';
export function isTokenTransferPayload(p) {
  return p.payloadType === PayloadType.TokenTransfer;
}
export function isContractCallPayload(p) {
  return p.payloadType === PayloadType.ContractCall;
}
export function isSmartContractPayload(p) {
  return p.payloadType === PayloadType.SmartContract;
}
export function isPoisonPayload(p) {
  return p.payloadType === PayloadType.PoisonMicroblock;
}
export function isCoinbasePayload(p) {
  return p.payloadType === PayloadType.Coinbase;
}
export function createTokenTransferPayload(recipient, amount, memo) {
  if (typeof recipient === 'string') {
    recipient = principalCV(recipient);
  }
  if (typeof memo === 'string') {
    memo = createMemoString(memo);
  }
  return {
    type: StacksMessageType.Payload,
    payloadType: PayloadType.TokenTransfer,
    recipient,
    amount: intToBigInt(amount, false),
    memo: memo ?? createMemoString('')
  };
}
export function createContractCallPayload(contractAddress, contractName, functionName, functionArgs) {
  if (typeof contractAddress === 'string') {
    contractAddress = createAddress(contractAddress);
  }
  if (typeof contractName === 'string') {
    contractName = createLPString(contractName);
  }
  if (typeof functionName === 'string') {
    functionName = createLPString(functionName);
  }
  return {
    type: StacksMessageType.Payload,
    payloadType: PayloadType.ContractCall,
    contractAddress,
    contractName,
    functionName,
    functionArgs
  };
}
export function createSmartContractPayload(contractName, codeBody, clarityVersion) {
  if (typeof contractName === 'string') {
    contractName = createLPString(contractName);
  }
  if (typeof codeBody === 'string') {
    codeBody = codeBodyString(codeBody);
  }
  if (typeof clarityVersion === 'number') {
    return {
      type: StacksMessageType.Payload,
      payloadType: PayloadType.VersionedSmartContract,
      clarityVersion,
      contractName,
      codeBody
    };
  }
  return {
    type: StacksMessageType.Payload,
    payloadType: PayloadType.SmartContract,
    contractName,
    codeBody
  };
}
export function createPoisonPayload() {
  return {
    type: StacksMessageType.Payload,
    payloadType: PayloadType.PoisonMicroblock
  };
}
export function createCoinbasePayload(coinbaseBytes, altRecipient) {
  if (coinbaseBytes.byteLength != COINBASE_BYTES_LENGTH) {
    throw Error(`Coinbase buffer size must be ${COINBASE_BYTES_LENGTH} bytes`);
  }
  if (altRecipient != undefined) {
    return {
      type: StacksMessageType.Payload,
      payloadType: PayloadType.CoinbaseToAltRecipient,
      coinbaseBytes,
      recipient: altRecipient
    };
  }
  return {
    type: StacksMessageType.Payload,
    payloadType: PayloadType.Coinbase,
    coinbaseBytes
  };
}
export function createNakamotoCoinbasePayload(coinbaseBytes, recipient, vrfProof) {
  if (coinbaseBytes.byteLength != COINBASE_BYTES_LENGTH) {
    throw Error(`Coinbase buffer size must be ${COINBASE_BYTES_LENGTH} bytes`);
  }
  if (vrfProof.byteLength != VRF_PROOF_BYTES_LENGTH) {
    throw Error(`VRF proof buffer size must be ${VRF_PROOF_BYTES_LENGTH} bytes`);
  }
  return {
    type: StacksMessageType.Payload,
    payloadType: PayloadType.NakamotoCoinbase,
    coinbaseBytes,
    recipient: recipient.type === ClarityType.OptionalSome ? recipient.value : undefined,
    vrfProof
  };
}
export var TenureChangeCause;
(function (TenureChangeCause) {
  TenureChangeCause[TenureChangeCause["BlockFound"] = 0] = "BlockFound";
  TenureChangeCause[TenureChangeCause["Extended"] = 1] = "Extended";
})(TenureChangeCause || (TenureChangeCause = {}));
export function createTenureChangePayload(tenureHash, previousTenureHash, burnViewHash, previousTenureEnd, previousTenureBlocks, cause, publicKeyHash) {
  return {
    type: StacksMessageType.Payload,
    payloadType: PayloadType.TenureChange,
    tenureHash,
    previousTenureHash,
    burnViewHash,
    previousTenureEnd,
    previousTenureBlocks,
    cause,
    publicKeyHash
  };
}
export function serializePayload(payload) {
  const bytesArray = [];
  bytesArray.push(payload.payloadType);
  switch (payload.payloadType) {
    case PayloadType.TokenTransfer:
      bytesArray.push(serializeCV(payload.recipient));
      bytesArray.push(intToBytes(payload.amount, false, 8));
      bytesArray.push(serializeStacksMessage(payload.memo));
      break;
    case PayloadType.ContractCall:
      bytesArray.push(serializeStacksMessage(payload.contractAddress));
      bytesArray.push(serializeStacksMessage(payload.contractName));
      bytesArray.push(serializeStacksMessage(payload.functionName));
      const numArgs = new Uint8Array(4);
      writeUInt32BE(numArgs, payload.functionArgs.length, 0);
      bytesArray.push(numArgs);
      payload.functionArgs.forEach(arg => {
        bytesArray.push(serializeCV(arg));
      });
      break;
    case PayloadType.SmartContract:
      bytesArray.push(serializeStacksMessage(payload.contractName));
      bytesArray.push(serializeStacksMessage(payload.codeBody));
      break;
    case PayloadType.VersionedSmartContract:
      bytesArray.push(payload.clarityVersion);
      bytesArray.push(serializeStacksMessage(payload.contractName));
      bytesArray.push(serializeStacksMessage(payload.codeBody));
      break;
    case PayloadType.PoisonMicroblock:
      break;
    case PayloadType.Coinbase:
      bytesArray.push(payload.coinbaseBytes);
      break;
    case PayloadType.CoinbaseToAltRecipient:
      bytesArray.push(payload.coinbaseBytes);
      bytesArray.push(serializeCV(payload.recipient));
      break;
    case PayloadType.NakamotoCoinbase:
      bytesArray.push(payload.coinbaseBytes);
      bytesArray.push(serializeCV(payload.recipient ? someCV(payload.recipient) : noneCV()));
      bytesArray.push(payload.vrfProof);
      break;
    case PayloadType.TenureChange:
      bytesArray.push(hexToBytes(payload.tenureHash));
      bytesArray.push(hexToBytes(payload.previousTenureHash));
      bytesArray.push(hexToBytes(payload.burnViewHash));
      bytesArray.push(hexToBytes(payload.previousTenureEnd));
      bytesArray.push(writeUInt32BE(new Uint8Array(4), payload.previousTenureBlocks));
      bytesArray.push(writeUInt8(new Uint8Array(1), payload.cause));
      bytesArray.push(hexToBytes(payload.publicKeyHash));
      break;
  }
  return concatArray(bytesArray);
}
export function deserializePayload(bytesReader) {
  const payloadType = bytesReader.readUInt8Enum(PayloadType, n => {
    throw new Error(`Cannot recognize PayloadType: ${n}`);
  });
  switch (payloadType) {
    case PayloadType.TokenTransfer:
      const recipient = deserializeCV(bytesReader);
      const amount = intToBigInt(bytesReader.readBytes(8), false);
      const memo = deserializeMemoString(bytesReader);
      return createTokenTransferPayload(recipient, amount, memo);
    case PayloadType.ContractCall:
      const contractAddress = deserializeAddress(bytesReader);
      const contractCallName = deserializeLPString(bytesReader);
      const functionName = deserializeLPString(bytesReader);
      const functionArgs = [];
      const numberOfArgs = bytesReader.readUInt32BE();
      for (let i = 0; i < numberOfArgs; i++) {
        const clarityValue = deserializeCV(bytesReader);
        functionArgs.push(clarityValue);
      }
      return createContractCallPayload(contractAddress, contractCallName, functionName, functionArgs);
    case PayloadType.SmartContract:
      const smartContractName = deserializeLPString(bytesReader);
      const codeBody = deserializeLPString(bytesReader, 4, 100000);
      return createSmartContractPayload(smartContractName, codeBody);
    case PayloadType.VersionedSmartContract:
      {
        const clarityVersion = bytesReader.readUInt8Enum(ClarityVersion, n => {
          throw new Error(`Cannot recognize ClarityVersion: ${n}`);
        });
        const smartContractName = deserializeLPString(bytesReader);
        const codeBody = deserializeLPString(bytesReader, 4, 100000);
        return createSmartContractPayload(smartContractName, codeBody, clarityVersion);
      }
    case PayloadType.PoisonMicroblock:
      return createPoisonPayload();
    case PayloadType.Coinbase:
      {
        const coinbaseBytes = bytesReader.readBytes(COINBASE_BYTES_LENGTH);
        return createCoinbasePayload(coinbaseBytes);
      }
    case PayloadType.CoinbaseToAltRecipient:
      {
        const coinbaseBytes = bytesReader.readBytes(COINBASE_BYTES_LENGTH);
        const altRecipient = deserializeCV(bytesReader);
        return createCoinbasePayload(coinbaseBytes, altRecipient);
      }
    case PayloadType.NakamotoCoinbase:
      {
        const coinbaseBytes = bytesReader.readBytes(COINBASE_BYTES_LENGTH);
        const recipient = deserializeCV(bytesReader);
        const vrfProof = bytesReader.readBytes(VRF_PROOF_BYTES_LENGTH);
        return createNakamotoCoinbasePayload(coinbaseBytes, recipient, vrfProof);
      }
    case PayloadType.TenureChange:
      const tenureHash = bytesToHex(bytesReader.readBytes(20));
      const previousTenureHash = bytesToHex(bytesReader.readBytes(20));
      const burnViewHash = bytesToHex(bytesReader.readBytes(20));
      const previousTenureEnd = bytesToHex(bytesReader.readBytes(32));
      const previousTenureBlocks = bytesReader.readUInt32BE();
      const cause = bytesReader.readUInt8Enum(TenureChangeCause, n => {
        throw new Error(`Cannot recognize TenureChangeCause: ${n}`);
      });
      const publicKeyHash = bytesToHex(bytesReader.readBytes(20));
      return createTenureChangePayload(tenureHash, previousTenureHash, burnViewHash, previousTenureEnd, previousTenureBlocks, cause, publicKeyHash);
  }
}
