import { ClarityType } from '../constants';
const stringAsciiCV = data => {
  return {
    type: ClarityType.StringASCII,
    data
  };
};
const stringUtf8CV = data => {
  return {
    type: ClarityType.StringUTF8,
    data
  };
};
const stringCV = (data, encoding) => {
  switch (encoding) {
    case 'ascii':
      return stringAsciiCV(data);
    case 'utf8':
      return stringUtf8CV(data);
  }
};
export { stringAsciiCV, stringUtf8CV, stringCV };
