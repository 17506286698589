export var ChainID;
(function (ChainID) {
  ChainID[ChainID["Testnet"] = 2147483648] = "Testnet";
  ChainID[ChainID["Mainnet"] = 1] = "Mainnet";
})(ChainID || (ChainID = {}));
export var TransactionVersion;
(function (TransactionVersion) {
  TransactionVersion[TransactionVersion["Mainnet"] = 0] = "Mainnet";
  TransactionVersion[TransactionVersion["Testnet"] = 128] = "Testnet";
})(TransactionVersion || (TransactionVersion = {}));
export var PeerNetworkID;
(function (PeerNetworkID) {
  PeerNetworkID[PeerNetworkID["Mainnet"] = 385875968] = "Mainnet";
  PeerNetworkID[PeerNetworkID["Testnet"] = 4278190080] = "Testnet";
})(PeerNetworkID || (PeerNetworkID = {}));
export const PRIVATE_KEY_COMPRESSED_LENGTH = 33;
export const PRIVATE_KEY_UNCOMPRESSED_LENGTH = 32;
export const BLOCKSTACK_DEFAULT_GAIA_HUB_URL = 'https://hub.blockstack.org';
