"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TokenSigner = exports.createUnsecuredToken = void 0;
const base64url = require("./base64Url");
const cryptoClients_1 = require("./cryptoClients");
const errors_1 = require("./errors");
const sha256_1 = require("./cryptoClients/sha256");
function createSigningInput(payload, header) {
  const tokenParts = [];
  // add in the header
  const encodedHeader = base64url.encode(JSON.stringify(header));
  tokenParts.push(encodedHeader);
  // add in the payload
  const encodedPayload = base64url.encode(JSON.stringify(payload));
  tokenParts.push(encodedPayload);
  // prepare the message
  const signingInput = tokenParts.join('.');
  // return the signing input
  return signingInput;
}
function createUnsecuredToken(payload) {
  const header = {
    typ: 'JWT',
    alg: 'none'
  };
  return createSigningInput(payload, header) + '.';
}
exports.createUnsecuredToken = createUnsecuredToken;
class TokenSigner {
  constructor(signingAlgorithm, rawPrivateKey) {
    if (!(signingAlgorithm && rawPrivateKey)) {
      throw new errors_1.MissingParametersError('a signing algorithm and private key are required');
    }
    if (typeof signingAlgorithm !== 'string') {
      throw new Error('signing algorithm parameter must be a string');
    }
    signingAlgorithm = signingAlgorithm.toUpperCase();
    if (!cryptoClients_1.cryptoClients.hasOwnProperty(signingAlgorithm)) {
      throw new Error('invalid signing algorithm');
    }
    this.tokenType = 'JWT';
    this.cryptoClient = cryptoClients_1.cryptoClients[signingAlgorithm];
    this.rawPrivateKey = rawPrivateKey;
  }
  header(header = {}) {
    const defaultHeader = {
      typ: this.tokenType,
      alg: this.cryptoClient.algorithmName
    };
    return Object.assign({}, defaultHeader, header);
  }
  sign(payload, expanded = false, customHeader = {}) {
    // generate the token header
    const header = this.header(customHeader);
    // prepare the message to be signed
    const signingInput = createSigningInput(payload, header);
    const signingInputHash = (0, sha256_1.hashSha256)(signingInput);
    return this.createWithSignedHash(payload, expanded, header, signingInput, signingInputHash);
  }
  signAsync(payload, expanded = false, customHeader = {}) {
    return __awaiter(this, void 0, void 0, function* () {
      // generate the token header
      const header = this.header(customHeader);
      // prepare the message to be signed
      const signingInput = createSigningInput(payload, header);
      const signingInputHash = yield (0, sha256_1.hashSha256Async)(signingInput);
      return this.createWithSignedHash(payload, expanded, header, signingInput, signingInputHash);
    });
  }
  createWithSignedHash(payload, expanded, header, signingInput, signingInputHash) {
    // sign the message and add in the signature
    const signature = this.cryptoClient.signHash(signingInputHash, this.rawPrivateKey);
    if (expanded) {
      const signedToken = {
        header: [base64url.encode(JSON.stringify(header))],
        payload: JSON.stringify(payload),
        signature: [signature]
      };
      return signedToken;
    } else {
      return [signingInput, signature].join('.');
    }
  }
}
exports.TokenSigner = TokenSigner;
