import { ClarityType } from '../constants';
import { isClarityName } from '../../utils';
function tupleCV(data) {
  for (const key in data) {
    if (!isClarityName(key)) {
      throw new Error(`"${key}" is not a valid Clarity name`);
    }
  }
  return {
    type: ClarityType.Tuple,
    data
  };
}
export { tupleCV };
