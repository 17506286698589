import { bytesToHex, concatArray, hexToBytes, intToBigInt, writeUInt32BE } from '@stacks/common';
import { deserializeAuthorization, intoInitialSighashAuth, isSingleSig, nextSignature, serializeAuthorization, setFee, setNonce, setSponsor, setSponsorNonce, verifyOrigin } from './authorization';
import { BytesReader } from './bytesReader';
import { AnchorMode, anchorModeFromNameOrValue, AuthType, DEFAULT_CHAIN_ID, PayloadType, PostConditionMode, PubKeyEncoding, StacksMessageType, TransactionVersion } from './constants';
import { SerializationError, SigningError } from './errors';
import { isCompressed } from './keys';
import { deserializePayload, serializePayload } from './payload';
import { createTransactionAuthField } from './signature';
import { createLPList, deserializeLPList, serializeLPList } from './types';
import { cloneDeep, txidFromData } from './utils';
export class StacksTransaction {
  constructor(version, auth, payload, postConditions, postConditionMode, anchorMode, chainId) {
    this.version = version;
    this.auth = auth;
    if ('amount' in payload) {
      this.payload = {
        ...payload,
        amount: intToBigInt(payload.amount, false)
      };
    } else {
      this.payload = payload;
    }
    this.chainId = chainId ?? DEFAULT_CHAIN_ID;
    this.postConditionMode = postConditionMode ?? PostConditionMode.Deny;
    this.postConditions = postConditions ?? createLPList([]);
    if (anchorMode) {
      this.anchorMode = anchorModeFromNameOrValue(anchorMode);
    } else {
      switch (payload.payloadType) {
        case PayloadType.Coinbase:
        case PayloadType.CoinbaseToAltRecipient:
        case PayloadType.NakamotoCoinbase:
        case PayloadType.PoisonMicroblock:
        case PayloadType.TenureChange:
          this.anchorMode = AnchorMode.OnChainOnly;
          break;
        case PayloadType.ContractCall:
        case PayloadType.SmartContract:
        case PayloadType.VersionedSmartContract:
        case PayloadType.TokenTransfer:
          this.anchorMode = AnchorMode.Any;
          break;
      }
    }
  }
  signBegin() {
    const tx = cloneDeep(this);
    tx.auth = intoInitialSighashAuth(tx.auth);
    return tx.txid();
  }
  verifyBegin() {
    const tx = cloneDeep(this);
    tx.auth = intoInitialSighashAuth(tx.auth);
    return tx.txid();
  }
  verifyOrigin() {
    return verifyOrigin(this.auth, this.verifyBegin());
  }
  signNextOrigin(sigHash, privateKey) {
    if (this.auth.spendingCondition === undefined) {
      throw new Error('"auth.spendingCondition" is undefined');
    }
    if (this.auth.authType === undefined) {
      throw new Error('"auth.authType" is undefined');
    }
    return this.signAndAppend(this.auth.spendingCondition, sigHash, AuthType.Standard, privateKey);
  }
  signNextSponsor(sigHash, privateKey) {
    if (this.auth.authType === AuthType.Sponsored) {
      return this.signAndAppend(this.auth.sponsorSpendingCondition, sigHash, AuthType.Sponsored, privateKey);
    } else {
      throw new Error('"auth.sponsorSpendingCondition" is undefined');
    }
  }
  appendPubkey(publicKey) {
    const cond = this.auth.spendingCondition;
    if (cond && !isSingleSig(cond)) {
      const compressed = isCompressed(publicKey);
      cond.fields.push(createTransactionAuthField(compressed ? PubKeyEncoding.Compressed : PubKeyEncoding.Uncompressed, publicKey));
    } else {
      throw new Error(`Can't append public key to a singlesig condition`);
    }
  }
  signAndAppend(condition, curSigHash, authType, privateKey) {
    const {
      nextSig,
      nextSigHash
    } = nextSignature(curSigHash, authType, condition.fee, condition.nonce, privateKey);
    if (isSingleSig(condition)) {
      condition.signature = nextSig;
    } else {
      const compressed = bytesToHex(privateKey.data).endsWith('01');
      condition.fields.push(createTransactionAuthField(compressed ? PubKeyEncoding.Compressed : PubKeyEncoding.Uncompressed, nextSig));
    }
    return nextSigHash;
  }
  txid() {
    const serialized = this.serialize();
    return txidFromData(serialized);
  }
  setSponsor(sponsorSpendingCondition) {
    if (this.auth.authType != AuthType.Sponsored) {
      throw new SigningError('Cannot sponsor sign a non-sponsored transaction');
    }
    this.auth = setSponsor(this.auth, sponsorSpendingCondition);
  }
  setFee(amount) {
    this.auth = setFee(this.auth, amount);
  }
  setNonce(nonce) {
    this.auth = setNonce(this.auth, nonce);
  }
  setSponsorNonce(nonce) {
    if (this.auth.authType != AuthType.Sponsored) {
      throw new SigningError('Cannot sponsor sign a non-sponsored transaction');
    }
    this.auth = setSponsorNonce(this.auth, nonce);
  }
  serialize() {
    if (this.version === undefined) {
      throw new SerializationError('"version" is undefined');
    }
    if (this.chainId === undefined) {
      throw new SerializationError('"chainId" is undefined');
    }
    if (this.auth === undefined) {
      throw new SerializationError('"auth" is undefined');
    }
    if (this.anchorMode === undefined) {
      throw new SerializationError('"anchorMode" is undefined');
    }
    if (this.payload === undefined) {
      throw new SerializationError('"payload" is undefined');
    }
    const bytesArray = [];
    bytesArray.push(this.version);
    const chainIdBytes = new Uint8Array(4);
    writeUInt32BE(chainIdBytes, this.chainId, 0);
    bytesArray.push(chainIdBytes);
    bytesArray.push(serializeAuthorization(this.auth));
    bytesArray.push(this.anchorMode);
    bytesArray.push(this.postConditionMode);
    bytesArray.push(serializeLPList(this.postConditions));
    bytesArray.push(serializePayload(this.payload));
    return concatArray(bytesArray);
  }
}
export function deserializeTransaction(tx) {
  let bytesReader;
  if (typeof tx === 'string') {
    if (tx.slice(0, 2).toLowerCase() === '0x') {
      bytesReader = new BytesReader(hexToBytes(tx.slice(2)));
    } else {
      bytesReader = new BytesReader(hexToBytes(tx));
    }
  } else if (tx instanceof Uint8Array) {
    bytesReader = new BytesReader(tx);
  } else {
    bytesReader = tx;
  }
  const version = bytesReader.readUInt8Enum(TransactionVersion, n => {
    throw new Error(`Could not parse ${n} as TransactionVersion`);
  });
  const chainId = bytesReader.readUInt32BE();
  const auth = deserializeAuthorization(bytesReader);
  const anchorMode = bytesReader.readUInt8Enum(AnchorMode, n => {
    throw new Error(`Could not parse ${n} as AnchorMode`);
  });
  const postConditionMode = bytesReader.readUInt8Enum(PostConditionMode, n => {
    throw new Error(`Could not parse ${n} as PostConditionMode`);
  });
  const postConditions = deserializeLPList(bytesReader, StacksMessageType.PostCondition);
  const payload = deserializePayload(bytesReader);
  return new StacksTransaction(version, auth, payload, postConditions, postConditionMode, anchorMode, chainId);
}
