import { intToBigInt } from '@stacks/common';
import { PostConditionType, StacksMessageType } from './constants';
import { parseAssetInfoString, parsePrincipalString } from './postcondition-types';
export function createSTXPostCondition(principal, conditionCode, amount) {
  if (typeof principal === 'string') {
    principal = parsePrincipalString(principal);
  }
  return {
    type: StacksMessageType.PostCondition,
    conditionType: PostConditionType.STX,
    principal,
    conditionCode,
    amount: intToBigInt(amount, false)
  };
}
export function createFungiblePostCondition(principal, conditionCode, amount, assetInfo) {
  if (typeof principal === 'string') {
    principal = parsePrincipalString(principal);
  }
  if (typeof assetInfo === 'string') {
    assetInfo = parseAssetInfoString(assetInfo);
  }
  return {
    type: StacksMessageType.PostCondition,
    conditionType: PostConditionType.Fungible,
    principal,
    conditionCode,
    amount: intToBigInt(amount, false),
    assetInfo
  };
}
export function createNonFungiblePostCondition(principal, conditionCode, assetInfo, assetName) {
  if (typeof principal === 'string') {
    principal = parsePrincipalString(principal);
  }
  if (typeof assetInfo === 'string') {
    assetInfo = parseAssetInfoString(assetInfo);
  }
  return {
    type: StacksMessageType.PostCondition,
    conditionType: PostConditionType.NonFungible,
    principal,
    conditionCode,
    assetInfo,
    assetName
  };
}
