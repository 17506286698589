import 'cross-fetch/polyfill';
const defaultFetchOpts = {
  referrerPolicy: 'origin',
  headers: {
    'x-hiro-product': 'stacksjs'
  }
};
export const getFetchOptions = () => {
  return defaultFetchOpts;
};
export const setFetchOptions = ops => {
  return Object.assign(defaultFetchOpts, ops);
};
export async function fetchWrapper(input, init) {
  const fetchOpts = {};
  Object.assign(fetchOpts, defaultFetchOpts, init);
  const fetchResult = await fetch(input, fetchOpts);
  return fetchResult;
}
export function hostMatches(host, pattern) {
  if (typeof pattern === 'string') return pattern === host;
  return pattern.exec(host);
}
export function createApiKeyMiddleware({
  apiKey,
  host = /(.*)api(.*)(\.stacks\.co|\.hiro\.so)$/i,
  httpHeader = 'x-api-key'
}) {
  return {
    pre: context => {
      const reqUrl = new URL(context.url);
      if (!hostMatches(reqUrl.host, host)) return;
      const headers = new Headers(context.init.headers);
      headers.set(httpHeader, apiKey);
      context.init.headers = headers;
    }
  };
}
function argsForCreateFetchFn(args) {
  let fetchLib = fetchWrapper;
  let middlewares = [];
  if (args.length > 0 && typeof args[0] === 'function') {
    fetchLib = args.shift();
  }
  if (args.length > 0) {
    middlewares = args;
  }
  return {
    fetchLib,
    middlewares
  };
}
export function createFetchFn(...args) {
  const {
    fetchLib,
    middlewares
  } = argsForCreateFetchFn(args);
  const fetchFn = async (url, init) => {
    let fetchParams = {
      url,
      init: init ?? {}
    };
    for (const middleware of middlewares) {
      if (typeof middleware.pre === 'function') {
        const result = await Promise.resolve(middleware.pre({
          fetch: fetchLib,
          ...fetchParams
        }));
        fetchParams = result ?? fetchParams;
      }
    }
    let response = await fetchLib(fetchParams.url, fetchParams.init);
    for (const middleware of middlewares) {
      if (typeof middleware.post === 'function') {
        const result = await Promise.resolve(middleware.post({
          fetch: fetchLib,
          url: fetchParams.url,
          init: fetchParams.init,
          response: response?.clone() ?? response
        }));
        response = result ?? response;
      }
    }
    return response;
  };
  return fetchFn;
}
