export var ChainID;
(function (ChainID) {
  ChainID[ChainID["Testnet"] = 2147483648] = "Testnet";
  ChainID[ChainID["Mainnet"] = 1] = "Mainnet";
})(ChainID || (ChainID = {}));
export const DEFAULT_CHAIN_ID = ChainID.Mainnet;
export const MAX_STRING_LENGTH_BYTES = 128;
export const CLARITY_INT_SIZE = 128;
export const CLARITY_INT_BYTE_SIZE = 16;
export const COINBASE_BYTES_LENGTH = 32;
export const VRF_PROOF_BYTES_LENGTH = 80;
export const RECOVERABLE_ECDSA_SIG_LENGTH_BYTES = 65;
export const COMPRESSED_PUBKEY_LENGTH_BYTES = 32;
export const UNCOMPRESSED_PUBKEY_LENGTH_BYTES = 64;
export const MEMO_MAX_LENGTH_BYTES = 34;
export const DEFAULT_CORE_NODE_API_URL = 'https://api.mainnet.hiro.so';
export var StacksMessageType;
(function (StacksMessageType) {
  StacksMessageType[StacksMessageType["Address"] = 0] = "Address";
  StacksMessageType[StacksMessageType["Principal"] = 1] = "Principal";
  StacksMessageType[StacksMessageType["LengthPrefixedString"] = 2] = "LengthPrefixedString";
  StacksMessageType[StacksMessageType["MemoString"] = 3] = "MemoString";
  StacksMessageType[StacksMessageType["AssetInfo"] = 4] = "AssetInfo";
  StacksMessageType[StacksMessageType["PostCondition"] = 5] = "PostCondition";
  StacksMessageType[StacksMessageType["PublicKey"] = 6] = "PublicKey";
  StacksMessageType[StacksMessageType["LengthPrefixedList"] = 7] = "LengthPrefixedList";
  StacksMessageType[StacksMessageType["Payload"] = 8] = "Payload";
  StacksMessageType[StacksMessageType["MessageSignature"] = 9] = "MessageSignature";
  StacksMessageType[StacksMessageType["StructuredDataSignature"] = 10] = "StructuredDataSignature";
  StacksMessageType[StacksMessageType["TransactionAuthField"] = 11] = "TransactionAuthField";
})(StacksMessageType || (StacksMessageType = {}));
export function whenMessageType(messageType) {
  return messageTypeMap => messageTypeMap[messageType];
}
export var PayloadType;
(function (PayloadType) {
  PayloadType[PayloadType["TokenTransfer"] = 0] = "TokenTransfer";
  PayloadType[PayloadType["SmartContract"] = 1] = "SmartContract";
  PayloadType[PayloadType["VersionedSmartContract"] = 6] = "VersionedSmartContract";
  PayloadType[PayloadType["ContractCall"] = 2] = "ContractCall";
  PayloadType[PayloadType["PoisonMicroblock"] = 3] = "PoisonMicroblock";
  PayloadType[PayloadType["Coinbase"] = 4] = "Coinbase";
  PayloadType[PayloadType["CoinbaseToAltRecipient"] = 5] = "CoinbaseToAltRecipient";
  PayloadType[PayloadType["TenureChange"] = 7] = "TenureChange";
  PayloadType[PayloadType["NakamotoCoinbase"] = 8] = "NakamotoCoinbase";
})(PayloadType || (PayloadType = {}));
export var ClarityVersion;
(function (ClarityVersion) {
  ClarityVersion[ClarityVersion["Clarity1"] = 1] = "Clarity1";
  ClarityVersion[ClarityVersion["Clarity2"] = 2] = "Clarity2";
})(ClarityVersion || (ClarityVersion = {}));
export var AnchorMode;
(function (AnchorMode) {
  AnchorMode[AnchorMode["OnChainOnly"] = 1] = "OnChainOnly";
  AnchorMode[AnchorMode["OffChainOnly"] = 2] = "OffChainOnly";
  AnchorMode[AnchorMode["Any"] = 3] = "Any";
})(AnchorMode || (AnchorMode = {}));
export const AnchorModeNames = ['onChainOnly', 'offChainOnly', 'any'];
const AnchorModeMap = {
  [AnchorModeNames[0]]: AnchorMode.OnChainOnly,
  [AnchorModeNames[1]]: AnchorMode.OffChainOnly,
  [AnchorModeNames[2]]: AnchorMode.Any,
  [AnchorMode.OnChainOnly]: AnchorMode.OnChainOnly,
  [AnchorMode.OffChainOnly]: AnchorMode.OffChainOnly,
  [AnchorMode.Any]: AnchorMode.Any
};
export function anchorModeFromNameOrValue(mode) {
  if (mode in AnchorModeMap) return AnchorModeMap[mode];
  throw new Error(`Invalid anchor mode "${mode}", must be one of: ${AnchorModeNames.join(', ')}`);
}
export var TransactionVersion;
(function (TransactionVersion) {
  TransactionVersion[TransactionVersion["Mainnet"] = 0] = "Mainnet";
  TransactionVersion[TransactionVersion["Testnet"] = 128] = "Testnet";
})(TransactionVersion || (TransactionVersion = {}));
export const DEFAULT_TRANSACTION_VERSION = TransactionVersion.Mainnet;
export var PostConditionMode;
(function (PostConditionMode) {
  PostConditionMode[PostConditionMode["Allow"] = 1] = "Allow";
  PostConditionMode[PostConditionMode["Deny"] = 2] = "Deny";
})(PostConditionMode || (PostConditionMode = {}));
export var PostConditionType;
(function (PostConditionType) {
  PostConditionType[PostConditionType["STX"] = 0] = "STX";
  PostConditionType[PostConditionType["Fungible"] = 1] = "Fungible";
  PostConditionType[PostConditionType["NonFungible"] = 2] = "NonFungible";
})(PostConditionType || (PostConditionType = {}));
export var AuthType;
(function (AuthType) {
  AuthType[AuthType["Standard"] = 4] = "Standard";
  AuthType[AuthType["Sponsored"] = 5] = "Sponsored";
})(AuthType || (AuthType = {}));
export var AddressHashMode;
(function (AddressHashMode) {
  AddressHashMode[AddressHashMode["SerializeP2PKH"] = 0] = "SerializeP2PKH";
  AddressHashMode[AddressHashMode["SerializeP2SH"] = 1] = "SerializeP2SH";
  AddressHashMode[AddressHashMode["SerializeP2WPKH"] = 2] = "SerializeP2WPKH";
  AddressHashMode[AddressHashMode["SerializeP2WSH"] = 3] = "SerializeP2WSH";
})(AddressHashMode || (AddressHashMode = {}));
export var AddressVersion;
(function (AddressVersion) {
  AddressVersion[AddressVersion["MainnetSingleSig"] = 22] = "MainnetSingleSig";
  AddressVersion[AddressVersion["MainnetMultiSig"] = 20] = "MainnetMultiSig";
  AddressVersion[AddressVersion["TestnetSingleSig"] = 26] = "TestnetSingleSig";
  AddressVersion[AddressVersion["TestnetMultiSig"] = 21] = "TestnetMultiSig";
})(AddressVersion || (AddressVersion = {}));
export var PubKeyEncoding;
(function (PubKeyEncoding) {
  PubKeyEncoding[PubKeyEncoding["Compressed"] = 0] = "Compressed";
  PubKeyEncoding[PubKeyEncoding["Uncompressed"] = 1] = "Uncompressed";
})(PubKeyEncoding || (PubKeyEncoding = {}));
export var FungibleConditionCode;
(function (FungibleConditionCode) {
  FungibleConditionCode[FungibleConditionCode["Equal"] = 1] = "Equal";
  FungibleConditionCode[FungibleConditionCode["Greater"] = 2] = "Greater";
  FungibleConditionCode[FungibleConditionCode["GreaterEqual"] = 3] = "GreaterEqual";
  FungibleConditionCode[FungibleConditionCode["Less"] = 4] = "Less";
  FungibleConditionCode[FungibleConditionCode["LessEqual"] = 5] = "LessEqual";
})(FungibleConditionCode || (FungibleConditionCode = {}));
export var NonFungibleConditionCode;
(function (NonFungibleConditionCode) {
  NonFungibleConditionCode[NonFungibleConditionCode["Sends"] = 16] = "Sends";
  NonFungibleConditionCode[NonFungibleConditionCode["DoesNotSend"] = 17] = "DoesNotSend";
})(NonFungibleConditionCode || (NonFungibleConditionCode = {}));
export var PostConditionPrincipalID;
(function (PostConditionPrincipalID) {
  PostConditionPrincipalID[PostConditionPrincipalID["Origin"] = 1] = "Origin";
  PostConditionPrincipalID[PostConditionPrincipalID["Standard"] = 2] = "Standard";
  PostConditionPrincipalID[PostConditionPrincipalID["Contract"] = 3] = "Contract";
})(PostConditionPrincipalID || (PostConditionPrincipalID = {}));
export var AssetType;
(function (AssetType) {
  AssetType[AssetType["STX"] = 0] = "STX";
  AssetType[AssetType["Fungible"] = 1] = "Fungible";
  AssetType[AssetType["NonFungible"] = 2] = "NonFungible";
})(AssetType || (AssetType = {}));
export var TxRejectedReason;
(function (TxRejectedReason) {
  TxRejectedReason["Serialization"] = "Serialization";
  TxRejectedReason["Deserialization"] = "Deserialization";
  TxRejectedReason["SignatureValidation"] = "SignatureValidation";
  TxRejectedReason["FeeTooLow"] = "FeeTooLow";
  TxRejectedReason["BadNonce"] = "BadNonce";
  TxRejectedReason["NotEnoughFunds"] = "NotEnoughFunds";
  TxRejectedReason["NoSuchContract"] = "NoSuchContract";
  TxRejectedReason["NoSuchPublicFunction"] = "NoSuchPublicFunction";
  TxRejectedReason["BadFunctionArgument"] = "BadFunctionArgument";
  TxRejectedReason["ContractAlreadyExists"] = "ContractAlreadyExists";
  TxRejectedReason["PoisonMicroblocksDoNotConflict"] = "PoisonMicroblocksDoNotConflict";
  TxRejectedReason["PoisonMicroblockHasUnknownPubKeyHash"] = "PoisonMicroblockHasUnknownPubKeyHash";
  TxRejectedReason["PoisonMicroblockIsInvalid"] = "PoisonMicroblockIsInvalid";
  TxRejectedReason["BadAddressVersionByte"] = "BadAddressVersionByte";
  TxRejectedReason["NoCoinbaseViaMempool"] = "NoCoinbaseViaMempool";
  TxRejectedReason["ServerFailureNoSuchChainTip"] = "ServerFailureNoSuchChainTip";
  TxRejectedReason["TooMuchChaining"] = "TooMuchChaining";
  TxRejectedReason["ConflictingNonceInMempool"] = "ConflictingNonceInMempool";
  TxRejectedReason["BadTransactionVersion"] = "BadTransactionVersion";
  TxRejectedReason["TransferRecipientCannotEqualSender"] = "TransferRecipientCannotEqualSender";
  TxRejectedReason["TransferAmountMustBePositive"] = "TransferAmountMustBePositive";
  TxRejectedReason["ServerFailureDatabase"] = "ServerFailureDatabase";
  TxRejectedReason["EstimatorError"] = "EstimatorError";
  TxRejectedReason["TemporarilyBlacklisted"] = "TemporarilyBlacklisted";
  TxRejectedReason["ServerFailureOther"] = "ServerFailureOther";
})(TxRejectedReason || (TxRejectedReason = {}));
