export function equals(a, b) {
  if (a.byteLength !== b.byteLength) return false;
  for (let i = 0; i < a.byteLength; i++) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}
export function alloc(length, value) {
  const a = new Uint8Array(length);
  for (let i = 0; i < length; i++) {
    a[i] = value;
  }
  return a;
}
export function readUInt16BE(source, offset) {
  return (source[offset + 0] << 8 | source[offset + 1]) >>> 0;
}
export function writeUInt16BE(destination, value, offset = 0) {
  destination[offset + 0] = value >>> 8;
  destination[offset + 1] = value >>> 0;
  return destination;
}
export function readUInt8(source, offset) {
  return source[offset];
}
export function writeUInt8(destination, value, offset = 0) {
  destination[offset] = value;
  return destination;
}
export function readUInt16LE(source, offset) {
  return source[offset + 0] << 0 >>> 0 | source[offset + 1] << 8 >>> 0;
}
export function writeUInt16LE(destination, value, offset = 0) {
  destination[offset + 0] = value & 255;
  value >>>= 8;
  destination[offset + 1] = value & 255;
  return destination;
}
export function readUInt32BE(source, offset) {
  return source[offset] * 2 ** 24 + source[offset + 1] * 2 ** 16 + source[offset + 2] * 2 ** 8 + source[offset + 3];
}
export function writeUInt32BE(destination, value, offset = 0) {
  destination[offset + 3] = value;
  value >>>= 8;
  destination[offset + 2] = value;
  value >>>= 8;
  destination[offset + 1] = value;
  value >>>= 8;
  destination[offset] = value;
  return destination;
}
export function readUInt32LE(source, offset) {
  return source[offset + 0] << 0 >>> 0 | source[offset + 1] << 8 >>> 0 | source[offset + 2] << 16 >>> 0 | source[offset + 3] << 24 >>> 0;
}
export function writeUInt32LE(destination, value, offset = 0) {
  destination[offset + 0] = value & 255;
  value >>>= 8;
  destination[offset + 1] = value & 255;
  value >>>= 8;
  destination[offset + 2] = value & 255;
  value >>>= 8;
  destination[offset + 3] = value & 255;
  return destination;
}
