import { DEFAULT_SCOPE, DEFAULT_BLOCKSTACK_HOST } from './constants';
import { getGlobalObject } from '@stacks/common';
export class AppConfig {
  constructor(scopes = DEFAULT_SCOPE.slice(), appDomain = getGlobalObject('location', {
    returnEmptyObject: true
  })?.origin, redirectPath = '', manifestPath = '/manifest.json', coreNode = undefined, authenticatorURL = DEFAULT_BLOCKSTACK_HOST) {
    this.appDomain = appDomain;
    this.scopes = scopes;
    this.redirectPath = redirectPath;
    this.manifestPath = manifestPath;
    this.coreNode = coreNode;
    this.authenticatorURL = authenticatorURL;
  }
  redirectURI() {
    return `${this.appDomain}${this.redirectPath}`;
  }
  manifestURI() {
    return `${this.appDomain}${this.manifestPath}`;
  }
}
