"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TokenVerifier = void 0;
const base64url = require("./base64Url");
const cryptoClients_1 = require("./cryptoClients");
const errors_1 = require("./errors");
const sha256_1 = require("./cryptoClients/sha256");
class TokenVerifier {
  constructor(signingAlgorithm, rawPublicKey) {
    if (!(signingAlgorithm && rawPublicKey)) {
      throw new errors_1.MissingParametersError('a signing algorithm and public key are required');
    }
    if (typeof signingAlgorithm !== 'string') {
      throw 'signing algorithm parameter must be a string';
    }
    signingAlgorithm = signingAlgorithm.toUpperCase();
    if (!cryptoClients_1.cryptoClients.hasOwnProperty(signingAlgorithm)) {
      throw 'invalid signing algorithm';
    }
    this.tokenType = 'JWT';
    this.cryptoClient = cryptoClients_1.cryptoClients[signingAlgorithm];
    this.rawPublicKey = rawPublicKey;
  }
  verify(token) {
    if (typeof token === 'string') {
      return this.verifyCompact(token, false);
    } else if (typeof token === 'object') {
      return this.verifyExpanded(token, false);
    } else {
      return false;
    }
  }
  verifyAsync(token) {
    if (typeof token === 'string') {
      return this.verifyCompact(token, true);
    } else if (typeof token === 'object') {
      return this.verifyExpanded(token, true);
    } else {
      return Promise.resolve(false);
    }
  }
  verifyCompact(token, async) {
    // decompose the token into parts
    const tokenParts = token.split('.');
    // calculate the signing input hash
    const signingInput = tokenParts[0] + '.' + tokenParts[1];
    const performVerify = signingInputHash => {
      // extract the signature as a DER array
      const derSignatureBytes = this.cryptoClient.loadSignature(tokenParts[2]);
      // verify the signed hash
      return this.cryptoClient.verifyHash(signingInputHash, derSignatureBytes, this.rawPublicKey);
    };
    if (async) {
      return (0, sha256_1.hashSha256Async)(signingInput).then(signingInputHash => performVerify(signingInputHash));
    } else {
      const signingInputHash = (0, sha256_1.hashSha256)(signingInput);
      return performVerify(signingInputHash);
    }
  }
  verifyExpanded(token, async) {
    const signingInput = [token['header'].join('.'), base64url.encode(token['payload'])].join('.');
    let verified = true;
    const performVerify = signingInputHash => {
      token['signature'].map(signature => {
        const derSignatureBytes = this.cryptoClient.loadSignature(signature);
        const signatureVerified = this.cryptoClient.verifyHash(signingInputHash, derSignatureBytes, this.rawPublicKey);
        if (!signatureVerified) {
          verified = false;
        }
      });
      return verified;
    };
    if (async) {
      return (0, sha256_1.hashSha256Async)(signingInput).then(signingInputHash => performVerify(signingInputHash));
    } else {
      const signingInputHash = (0, sha256_1.hashSha256)(signingInput);
      return performVerify(signingInputHash);
    }
  }
}
exports.TokenVerifier = TokenVerifier;
