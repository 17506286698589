import { ClarityType } from '../constants';
function noneCV() {
  return {
    type: ClarityType.OptionalNone
  };
}
function someCV(value) {
  return {
    type: ClarityType.OptionalSome,
    value
  };
}
function optionalCVOf(value) {
  if (value) {
    return someCV(value);
  } else {
    return noneCV();
  }
}
export { noneCV, someCV, optionalCVOf };
