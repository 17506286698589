import { AddressHashMode, AddressVersion, RECOVERABLE_ECDSA_SIG_LENGTH_BYTES, StacksMessageType, TransactionVersion } from './constants';
import { c32address } from 'c32check';
import { hexToBytes } from '@stacks/common';
export function createMessageSignature(signature) {
  const length = hexToBytes(signature).byteLength;
  if (length != RECOVERABLE_ECDSA_SIG_LENGTH_BYTES) {
    throw Error('Invalid signature');
  }
  return {
    type: StacksMessageType.MessageSignature,
    data: signature
  };
}
export function addressHashModeToVersion(hashMode, txVersion) {
  switch (hashMode) {
    case AddressHashMode.SerializeP2PKH:
      switch (txVersion) {
        case TransactionVersion.Mainnet:
          return AddressVersion.MainnetSingleSig;
        case TransactionVersion.Testnet:
          return AddressVersion.TestnetSingleSig;
        default:
          throw new Error(`Unexpected txVersion ${JSON.stringify(txVersion)} for hashMode ${hashMode}`);
      }
    case AddressHashMode.SerializeP2SH:
    case AddressHashMode.SerializeP2WPKH:
    case AddressHashMode.SerializeP2WSH:
      switch (txVersion) {
        case TransactionVersion.Mainnet:
          return AddressVersion.MainnetMultiSig;
        case TransactionVersion.Testnet:
          return AddressVersion.TestnetMultiSig;
        default:
          throw new Error(`Unexpected txVersion ${JSON.stringify(txVersion)} for hashMode ${hashMode}`);
      }
    default:
      throw new Error(`Unexpected hashMode ${JSON.stringify(hashMode)}`);
  }
}
export function addressFromVersionHash(version, hash) {
  return {
    type: StacksMessageType.Address,
    version,
    hash160: hash
  };
}
export function addressToString(address) {
  return c32address(address.version, address.hash160);
}
