import { InvalidStateError } from '@stacks/common';
const SESSION_VERSION = '1.0.0';
export class SessionData {
  constructor(options) {
    this.version = SESSION_VERSION;
    this.userData = options.userData;
    this.transitKey = options.transitKey;
    this.etags = options.etags ? options.etags : {};
  }
  static fromJSON(json) {
    if (json.version !== SESSION_VERSION) {
      throw new InvalidStateError(`JSON data version ${json.version} not supported by SessionData`);
    }
    const options = {
      coreNode: json.coreNode,
      userData: json.userData,
      transitKey: json.transitKey,
      etags: json.etags
    };
    return new SessionData(options);
  }
  toString() {
    return JSON.stringify(this);
  }
}
