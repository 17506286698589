import { bytesToHex, concatArray, hexToBytes, intToBigInt, intToBytes, writeUInt16BE } from '@stacks/common';
import { AddressHashMode, AuthType, PubKeyEncoding, RECOVERABLE_ECDSA_SIG_LENGTH_BYTES, StacksMessageType } from './constants';
import { cloneDeep, leftPadHex, txidFromData } from './utils';
import { serializeMessageSignature, deserializeMessageSignature } from './signature';
import { addressFromPublicKeys, createEmptyAddress, createLPList, deserializeLPList, serializeLPList } from './types';
import { createStacksPublicKey, getPublicKey, isCompressed, publicKeyFromSignatureVrs, signWithKey } from './keys';
import { DeserializationError, SigningError, VerificationError } from './errors';
export function emptyMessageSignature() {
  return {
    type: StacksMessageType.MessageSignature,
    data: bytesToHex(new Uint8Array(RECOVERABLE_ECDSA_SIG_LENGTH_BYTES))
  };
}
export function createSingleSigSpendingCondition(hashMode, pubKey, nonce, fee) {
  const signer = addressFromPublicKeys(0, hashMode, 1, [createStacksPublicKey(pubKey)]).hash160;
  const keyEncoding = isCompressed(createStacksPublicKey(pubKey)) ? PubKeyEncoding.Compressed : PubKeyEncoding.Uncompressed;
  return {
    hashMode,
    signer,
    nonce: intToBigInt(nonce, false),
    fee: intToBigInt(fee, false),
    keyEncoding,
    signature: emptyMessageSignature()
  };
}
export function createMultiSigSpendingCondition(hashMode, numSigs, pubKeys, nonce, fee) {
  const stacksPublicKeys = pubKeys.map(createStacksPublicKey);
  const signer = addressFromPublicKeys(0, hashMode, numSigs, stacksPublicKeys).hash160;
  return {
    hashMode,
    signer,
    nonce: intToBigInt(nonce, false),
    fee: intToBigInt(fee, false),
    fields: [],
    signaturesRequired: numSigs
  };
}
export function isSingleSig(condition) {
  return 'signature' in condition;
}
function clearCondition(condition) {
  const cloned = cloneDeep(condition);
  cloned.nonce = 0;
  cloned.fee = 0;
  if (isSingleSig(cloned)) {
    cloned.signature = emptyMessageSignature();
  } else {
    cloned.fields = [];
  }
  return {
    ...cloned,
    nonce: BigInt(0),
    fee: BigInt(0)
  };
}
export function serializeSingleSigSpendingCondition(condition) {
  const bytesArray = [condition.hashMode, hexToBytes(condition.signer), intToBytes(condition.nonce, false, 8), intToBytes(condition.fee, false, 8), condition.keyEncoding, serializeMessageSignature(condition.signature)];
  return concatArray(bytesArray);
}
export function serializeMultiSigSpendingCondition(condition) {
  const bytesArray = [condition.hashMode, hexToBytes(condition.signer), intToBytes(condition.nonce, false, 8), intToBytes(condition.fee, false, 8)];
  const fields = createLPList(condition.fields);
  bytesArray.push(serializeLPList(fields));
  const numSigs = new Uint8Array(2);
  writeUInt16BE(numSigs, condition.signaturesRequired, 0);
  bytesArray.push(numSigs);
  return concatArray(bytesArray);
}
export function deserializeSingleSigSpendingCondition(hashMode, bytesReader) {
  const signer = bytesToHex(bytesReader.readBytes(20));
  const nonce = BigInt(`0x${bytesToHex(bytesReader.readBytes(8))}`);
  const fee = BigInt(`0x${bytesToHex(bytesReader.readBytes(8))}`);
  const keyEncoding = bytesReader.readUInt8Enum(PubKeyEncoding, n => {
    throw new DeserializationError(`Could not parse ${n} as PubKeyEncoding`);
  });
  if (hashMode === AddressHashMode.SerializeP2WPKH && keyEncoding != PubKeyEncoding.Compressed) {
    throw new DeserializationError('Failed to parse singlesig spending condition: incomaptible hash mode and key encoding');
  }
  const signature = deserializeMessageSignature(bytesReader);
  return {
    hashMode,
    signer,
    nonce,
    fee,
    keyEncoding,
    signature
  };
}
export function deserializeMultiSigSpendingCondition(hashMode, bytesReader) {
  const signer = bytesToHex(bytesReader.readBytes(20));
  const nonce = BigInt('0x' + bytesToHex(bytesReader.readBytes(8)));
  const fee = BigInt('0x' + bytesToHex(bytesReader.readBytes(8)));
  const fields = deserializeLPList(bytesReader, StacksMessageType.TransactionAuthField).values;
  let haveUncompressed = false;
  let numSigs = 0;
  for (const field of fields) {
    switch (field.contents.type) {
      case StacksMessageType.PublicKey:
        if (!isCompressed(field.contents)) haveUncompressed = true;
        break;
      case StacksMessageType.MessageSignature:
        if (field.pubKeyEncoding === PubKeyEncoding.Uncompressed) haveUncompressed = true;
        numSigs += 1;
        if (numSigs === 65536) throw new VerificationError('Failed to parse multisig spending condition: too many signatures');
        break;
    }
  }
  const signaturesRequired = bytesReader.readUInt16BE();
  if (haveUncompressed && hashMode === AddressHashMode.SerializeP2SH) throw new VerificationError('Uncompressed keys are not allowed in this hash mode');
  return {
    hashMode,
    signer,
    nonce,
    fee,
    fields,
    signaturesRequired
  };
}
export function serializeSpendingCondition(condition) {
  if (isSingleSig(condition)) {
    return serializeSingleSigSpendingCondition(condition);
  }
  return serializeMultiSigSpendingCondition(condition);
}
export function deserializeSpendingCondition(bytesReader) {
  const hashMode = bytesReader.readUInt8Enum(AddressHashMode, n => {
    throw new DeserializationError(`Could not parse ${n} as AddressHashMode`);
  });
  if (hashMode === AddressHashMode.SerializeP2PKH || hashMode === AddressHashMode.SerializeP2WPKH) {
    return deserializeSingleSigSpendingCondition(hashMode, bytesReader);
  } else {
    return deserializeMultiSigSpendingCondition(hashMode, bytesReader);
  }
}
export function makeSigHashPreSign(curSigHash, authType, fee, nonce) {
  const hashLength = 32 + 1 + 8 + 8;
  const sigHash = curSigHash + bytesToHex(new Uint8Array([authType])) + bytesToHex(intToBytes(fee, false, 8)) + bytesToHex(intToBytes(nonce, false, 8));
  if (hexToBytes(sigHash).byteLength !== hashLength) {
    throw Error('Invalid signature hash length');
  }
  return txidFromData(hexToBytes(sigHash));
}
function makeSigHashPostSign(curSigHash, pubKey, signature) {
  const hashLength = 32 + 1 + RECOVERABLE_ECDSA_SIG_LENGTH_BYTES;
  const pubKeyEncoding = isCompressed(pubKey) ? PubKeyEncoding.Compressed : PubKeyEncoding.Uncompressed;
  const sigHash = curSigHash + leftPadHex(pubKeyEncoding.toString(16)) + signature.data;
  const sigHashBytes = hexToBytes(sigHash);
  if (sigHashBytes.byteLength > hashLength) {
    throw Error('Invalid signature hash length');
  }
  return txidFromData(sigHashBytes);
}
export function nextSignature(curSigHash, authType, fee, nonce, privateKey) {
  const sigHashPreSign = makeSigHashPreSign(curSigHash, authType, fee, nonce);
  const signature = signWithKey(privateKey, sigHashPreSign);
  const publicKey = getPublicKey(privateKey);
  const nextSigHash = makeSigHashPostSign(sigHashPreSign, publicKey, signature);
  return {
    nextSig: signature,
    nextSigHash
  };
}
export function nextVerification(initialSigHash, authType, fee, nonce, pubKeyEncoding, signature) {
  const sigHashPreSign = makeSigHashPreSign(initialSigHash, authType, fee, nonce);
  const publicKey = createStacksPublicKey(publicKeyFromSignatureVrs(sigHashPreSign, signature, pubKeyEncoding));
  const nextSigHash = makeSigHashPostSign(sigHashPreSign, publicKey, signature);
  return {
    pubKey: publicKey,
    nextSigHash
  };
}
function newInitialSigHash() {
  const spendingCondition = createSingleSigSpendingCondition(AddressHashMode.SerializeP2PKH, '', 0, 0);
  spendingCondition.signer = createEmptyAddress().hash160;
  spendingCondition.keyEncoding = PubKeyEncoding.Compressed;
  spendingCondition.signature = emptyMessageSignature();
  return spendingCondition;
}
function verify(condition, initialSigHash, authType) {
  if (isSingleSig(condition)) {
    return verifySingleSig(condition, initialSigHash, authType);
  } else {
    return verifyMultiSig(condition, initialSigHash, authType);
  }
}
function verifySingleSig(condition, initialSigHash, authType) {
  const {
    pubKey,
    nextSigHash
  } = nextVerification(initialSigHash, authType, condition.fee, condition.nonce, condition.keyEncoding, condition.signature);
  const addrBytes = addressFromPublicKeys(0, condition.hashMode, 1, [pubKey]).hash160;
  if (addrBytes !== condition.signer) throw new VerificationError(`Signer hash does not equal hash of public key(s): ${addrBytes} != ${condition.signer}`);
  return nextSigHash;
}
function verifyMultiSig(condition, initialSigHash, authType) {
  const publicKeys = [];
  let curSigHash = initialSigHash;
  let haveUncompressed = false;
  let numSigs = 0;
  for (const field of condition.fields) {
    let foundPubKey;
    switch (field.contents.type) {
      case StacksMessageType.PublicKey:
        if (!isCompressed(field.contents)) haveUncompressed = true;
        foundPubKey = field.contents;
        break;
      case StacksMessageType.MessageSignature:
        if (field.pubKeyEncoding === PubKeyEncoding.Uncompressed) haveUncompressed = true;
        const {
          pubKey,
          nextSigHash
        } = nextVerification(curSigHash, authType, condition.fee, condition.nonce, field.pubKeyEncoding, field.contents);
        curSigHash = nextSigHash;
        foundPubKey = pubKey;
        numSigs += 1;
        if (numSigs === 65536) throw new VerificationError('Too many signatures');
        break;
    }
    publicKeys.push(foundPubKey);
  }
  if (numSigs !== condition.signaturesRequired) throw new VerificationError('Incorrect number of signatures');
  if (haveUncompressed && condition.hashMode === AddressHashMode.SerializeP2SH) throw new VerificationError('Uncompressed keys are not allowed in this hash mode');
  const addrBytes = addressFromPublicKeys(0, condition.hashMode, condition.signaturesRequired, publicKeys).hash160;
  if (addrBytes !== condition.signer) throw new VerificationError(`Signer hash does not equal hash of public key(s): ${addrBytes} != ${condition.signer}`);
  return curSigHash;
}
export function createStandardAuth(spendingCondition) {
  return {
    authType: AuthType.Standard,
    spendingCondition
  };
}
export function createSponsoredAuth(spendingCondition, sponsorSpendingCondition) {
  return {
    authType: AuthType.Sponsored,
    spendingCondition,
    sponsorSpendingCondition: sponsorSpendingCondition ? sponsorSpendingCondition : createSingleSigSpendingCondition(AddressHashMode.SerializeP2PKH, '0'.repeat(66), 0, 0)
  };
}
export function intoInitialSighashAuth(auth) {
  if (auth.spendingCondition) {
    switch (auth.authType) {
      case AuthType.Standard:
        return createStandardAuth(clearCondition(auth.spendingCondition));
      case AuthType.Sponsored:
        return createSponsoredAuth(clearCondition(auth.spendingCondition), newInitialSigHash());
      default:
        throw new SigningError('Unexpected authorization type for signing');
    }
  }
  throw new Error('Authorization missing SpendingCondition');
}
export function verifyOrigin(auth, initialSigHash) {
  switch (auth.authType) {
    case AuthType.Standard:
      return verify(auth.spendingCondition, initialSigHash, AuthType.Standard);
    case AuthType.Sponsored:
      return verify(auth.spendingCondition, initialSigHash, AuthType.Standard);
    default:
      throw new SigningError('Invalid origin auth type');
  }
}
export function setFee(auth, amount) {
  switch (auth.authType) {
    case AuthType.Standard:
      const spendingCondition = {
        ...auth.spendingCondition,
        fee: intToBigInt(amount, false)
      };
      return {
        ...auth,
        spendingCondition
      };
    case AuthType.Sponsored:
      const sponsorSpendingCondition = {
        ...auth.sponsorSpendingCondition,
        fee: intToBigInt(amount, false)
      };
      return {
        ...auth,
        sponsorSpendingCondition
      };
  }
}
export function getFee(auth) {
  switch (auth.authType) {
    case AuthType.Standard:
      return auth.spendingCondition.fee;
    case AuthType.Sponsored:
      return auth.sponsorSpendingCondition.fee;
  }
}
export function setNonce(auth, nonce) {
  const spendingCondition = {
    ...auth.spendingCondition,
    nonce: intToBigInt(nonce, false)
  };
  return {
    ...auth,
    spendingCondition
  };
}
export function setSponsorNonce(auth, nonce) {
  const sponsorSpendingCondition = {
    ...auth.sponsorSpendingCondition,
    nonce: intToBigInt(nonce, false)
  };
  return {
    ...auth,
    sponsorSpendingCondition
  };
}
export function setSponsor(auth, sponsorSpendingCondition) {
  const sc = {
    ...sponsorSpendingCondition,
    nonce: intToBigInt(sponsorSpendingCondition.nonce, false),
    fee: intToBigInt(sponsorSpendingCondition.fee, false)
  };
  return {
    ...auth,
    sponsorSpendingCondition: sc
  };
}
export function serializeAuthorization(auth) {
  const bytesArray = [];
  bytesArray.push(auth.authType);
  switch (auth.authType) {
    case AuthType.Standard:
      bytesArray.push(serializeSpendingCondition(auth.spendingCondition));
      break;
    case AuthType.Sponsored:
      bytesArray.push(serializeSpendingCondition(auth.spendingCondition));
      bytesArray.push(serializeSpendingCondition(auth.sponsorSpendingCondition));
      break;
  }
  return concatArray(bytesArray);
}
export function deserializeAuthorization(bytesReader) {
  const authType = bytesReader.readUInt8Enum(AuthType, n => {
    throw new DeserializationError(`Could not parse ${n} as AuthType`);
  });
  let spendingCondition;
  switch (authType) {
    case AuthType.Standard:
      spendingCondition = deserializeSpendingCondition(bytesReader);
      return createStandardAuth(spendingCondition);
    case AuthType.Sponsored:
      spendingCondition = deserializeSpendingCondition(bytesReader);
      const sponsorSpendingCondition = deserializeSpendingCondition(bytesReader);
      return createSponsoredAuth(spendingCondition, sponsorSpendingCondition);
  }
}
