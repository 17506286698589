import { asciiToBytes, hexToBytes, utf8ToBytes } from '@stacks/common';
import { boolCV, bufferCV, contractPrincipalCV, deserializeCV, intCV, listCV, noneCV, responseErrorCV, responseOkCV, serializeCV, someCV, standardPrincipalCV, stringAsciiCV, stringUtf8CV, tupleCV, uintCV } from './clarity';
export { prettyPrint } from './clarity/prettyPrint';
export const bool = boolCV;
export const int = intCV;
export const uint = uintCV;
export function principal(address) {
  const [addr, name] = address.split('.');
  return name ? contractPrincipalCV(addr, name) : standardPrincipalCV(addr);
}
export const address = principal;
export const contractPrincipal = contractPrincipalCV;
export const standardPrincipal = standardPrincipalCV;
export const list = listCV;
export const stringAscii = stringAsciiCV;
export const stringUtf8 = stringUtf8CV;
export const buffer = bufferCV;
export const bufferFromHex = hex => bufferCV(hexToBytes(hex));
export const bufferFromAscii = ascii => bufferCV(asciiToBytes(ascii));
export const bufferFromUtf8 = utf8 => bufferCV(utf8ToBytes(utf8));
export const none = noneCV;
export const some = someCV;
export const ok = responseOkCV;
export const error = responseErrorCV;
export const tuple = tupleCV;
export const serialize = serializeCV;
export const deserialize = deserializeCV;
