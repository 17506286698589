import { bytesToHex, hexToBytes, intToBigInt } from '@stacks/common';
import { StacksNetwork, StacksMainnet, StacksTestnet, createFetchFn } from '@stacks/network';
import { c32address } from 'c32check';
import { createMultiSigSpendingCondition, createSingleSigSpendingCondition, createSponsoredAuth, createStandardAuth } from './authorization';
import { deserializeCV, serializeCV } from './clarity';
import { AddressHashMode, AddressVersion, PayloadType, PostConditionMode, TransactionVersion, RECOVERABLE_ECDSA_SIG_LENGTH_BYTES, StacksMessageType, ClarityVersion } from './constants';
import { validateContractCall } from './contract-abi';
import { NoEstimateAvailableError } from './errors';
import { createStacksPrivateKey, getPublicKey, pubKeyfromPrivKey, publicKeyFromBytes, publicKeyToAddress, publicKeyToString } from './keys';
import { createContractCallPayload, createSmartContractPayload, createTokenTransferPayload, serializePayload } from './payload';
import { createFungiblePostCondition, createNonFungiblePostCondition, createSTXPostCondition } from './postcondition';
import { createContractPrincipal, createStandardPrincipal } from './postcondition-types';
import { TransactionSigner } from './signer';
import { StacksTransaction } from './transaction';
import { createLPList } from './types';
import { cvToHex, omit, parseReadOnlyResponse, validateTxId } from './utils';
export async function getNonce(address, network) {
  const derivedNetwork = StacksNetwork.fromNameOrNetwork(network ?? new StacksMainnet());
  const url = derivedNetwork.getAccountApiUrl(address);
  const response = await derivedNetwork.fetchFn(url);
  if (!response.ok) {
    let msg = '';
    try {
      msg = await response.text();
    } catch (error) {}
    throw new Error(`Error fetching nonce. Response ${response.status}: ${response.statusText}. Attempted to fetch ${url} and failed with the message: "${msg}"`);
  }
  const responseText = await response.text();
  const result = JSON.parse(responseText);
  return BigInt(result.nonce);
}
export async function estimateTransfer(transaction, network) {
  if (transaction.payload.payloadType !== PayloadType.TokenTransfer) {
    throw new Error(`Transaction fee estimation only possible with ${PayloadType[PayloadType.TokenTransfer]} transactions. Invoked with: ${PayloadType[transaction.payload.payloadType]}`);
  }
  return estimateTransferUnsafe(transaction, network);
}
export async function estimateTransferUnsafe(transaction, network) {
  const requestHeaders = {
    Accept: 'application/text'
  };
  const fetchOptions = {
    method: 'GET',
    headers: requestHeaders
  };
  const derivedNetwork = StacksNetwork.fromNameOrNetwork(network ?? deriveNetwork(transaction));
  const url = derivedNetwork.getTransferFeeEstimateApiUrl();
  const response = await derivedNetwork.fetchFn(url, fetchOptions);
  if (!response.ok) {
    let msg = '';
    try {
      msg = await response.text();
    } catch (error) {}
    throw new Error(`Error estimating transaction fee. Response ${response.status}: ${response.statusText}. Attempted to fetch ${url} and failed with the message: "${msg}"`);
  }
  const feeRateResult = await response.text();
  const txBytes = BigInt(transaction.serialize().byteLength);
  const feeRate = BigInt(feeRateResult);
  return feeRate * txBytes;
}
export async function estimateTransaction(transactionPayload, estimatedLen, network) {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      transaction_payload: bytesToHex(serializePayload(transactionPayload)),
      ...(estimatedLen ? {
        estimated_len: estimatedLen
      } : {})
    })
  };
  const derivedNetwork = StacksNetwork.fromNameOrNetwork(network ?? new StacksMainnet());
  const url = derivedNetwork.getTransactionFeeEstimateApiUrl();
  const response = await derivedNetwork.fetchFn(url, options);
  if (!response.ok) {
    const body = await response.text().then(str => {
      try {
        return JSON.parse(str);
      } catch (error) {
        return str;
      }
    });
    if (body?.reason === 'NoEstimateAvailable' || typeof body === 'string' && body.includes('NoEstimateAvailable')) {
      throw new NoEstimateAvailableError(body?.reason_data?.message ?? '');
    }
    throw new Error(`Error estimating transaction fee. Response ${response.status}: ${response.statusText}. Attempted to fetch ${url} and failed with the message: "${body}"`);
  }
  const data = await response.json();
  return data.estimations;
}
export async function broadcastTransaction(transaction, network, attachment) {
  const rawTx = transaction.serialize();
  const derivedNetwork = StacksNetwork.fromNameOrNetwork(network ?? deriveNetwork(transaction));
  const url = derivedNetwork.getBroadcastApiUrl();
  return broadcastRawTransaction(rawTx, url, attachment, derivedNetwork.fetchFn);
}
export async function broadcastRawTransaction(rawTx, url, attachment, fetchFn = createFetchFn()) {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': attachment ? 'application/json' : 'application/octet-stream'
    },
    body: attachment ? JSON.stringify({
      tx: bytesToHex(rawTx),
      attachment: bytesToHex(attachment)
    }) : rawTx
  };
  const response = await fetchFn(url, options);
  if (!response.ok) {
    try {
      return await response.json();
    } catch (e) {
      throw Error(`Failed to broadcast transaction: ${e.message}`);
    }
  }
  const text = await response.text();
  const txid = text.replace(/["]+/g, '');
  if (!validateTxId(txid)) throw new Error(text);
  return {
    txid
  };
}
export async function getAbi(address, contractName, network) {
  const options = {
    method: 'GET'
  };
  const derivedNetwork = StacksNetwork.fromNameOrNetwork(network);
  const url = derivedNetwork.getAbiApiUrl(address, contractName);
  const response = await derivedNetwork.fetchFn(url, options);
  if (!response.ok) {
    const msg = await response.text().catch(() => '');
    throw new Error(`Error fetching contract ABI for contract "${contractName}" at address ${address}. Response ${response.status}: ${response.statusText}. Attempted to fetch ${url} and failed with the message: "${msg}"`);
  }
  return JSON.parse(await response.text());
}
function deriveNetwork(transaction) {
  switch (transaction.version) {
    case TransactionVersion.Mainnet:
      return new StacksMainnet();
    case TransactionVersion.Testnet:
      return new StacksTestnet();
  }
}
export async function makeUnsignedSTXTokenTransfer(txOptions) {
  const defaultOptions = {
    fee: BigInt(0),
    nonce: BigInt(0),
    network: new StacksMainnet(),
    memo: '',
    sponsored: false
  };
  const options = Object.assign(defaultOptions, txOptions);
  const payload = createTokenTransferPayload(options.recipient, options.amount, options.memo);
  let authorization = null;
  let spendingCondition = null;
  if ('publicKey' in options) {
    spendingCondition = createSingleSigSpendingCondition(AddressHashMode.SerializeP2PKH, options.publicKey, options.nonce, options.fee);
  } else {
    spendingCondition = createMultiSigSpendingCondition(AddressHashMode.SerializeP2SH, options.numSignatures, options.publicKeys, options.nonce, options.fee);
  }
  if (options.sponsored) {
    authorization = createSponsoredAuth(spendingCondition);
  } else {
    authorization = createStandardAuth(spendingCondition);
  }
  const network = StacksNetwork.fromNameOrNetwork(options.network);
  const transaction = new StacksTransaction(network.version, authorization, payload, undefined, undefined, options.anchorMode, network.chainId);
  if (txOptions.fee === undefined || txOptions.fee === null) {
    const fee = await estimateTransactionFeeWithFallback(transaction, network);
    transaction.setFee(fee);
  }
  if (txOptions.nonce === undefined || txOptions.nonce === null) {
    const addressVersion = options.network.version === TransactionVersion.Mainnet ? AddressVersion.MainnetSingleSig : AddressVersion.TestnetSingleSig;
    const senderAddress = c32address(addressVersion, transaction.auth.spendingCondition.signer);
    const txNonce = await getNonce(senderAddress, options.network);
    transaction.setNonce(txNonce);
  }
  return transaction;
}
export async function makeSTXTokenTransfer(txOptions) {
  if ('senderKey' in txOptions) {
    const publicKey = publicKeyToString(getPublicKey(createStacksPrivateKey(txOptions.senderKey)));
    const options = omit(txOptions, 'senderKey');
    const transaction = await makeUnsignedSTXTokenTransfer({
      publicKey,
      ...options
    });
    const privKey = createStacksPrivateKey(txOptions.senderKey);
    const signer = new TransactionSigner(transaction);
    signer.signOrigin(privKey);
    return transaction;
  } else {
    const options = omit(txOptions, 'signerKeys');
    const transaction = await makeUnsignedSTXTokenTransfer(options);
    const signer = new TransactionSigner(transaction);
    let pubKeys = txOptions.publicKeys;
    for (const key of txOptions.signerKeys) {
      const pubKey = pubKeyfromPrivKey(key);
      pubKeys = pubKeys.filter(pk => pk !== bytesToHex(pubKey.data));
      signer.signOrigin(createStacksPrivateKey(key));
    }
    for (const key of pubKeys) {
      signer.appendOrigin(publicKeyFromBytes(hexToBytes(key)));
    }
    return transaction;
  }
}
export async function estimateContractDeploy(transaction, network) {
  if (transaction.payload.payloadType !== PayloadType.SmartContract && transaction.payload.payloadType !== PayloadType.VersionedSmartContract) {
    throw new Error(`Contract deploy fee estimation only possible with ${PayloadType[PayloadType.SmartContract]} transactions. Invoked with: ${PayloadType[transaction.payload.payloadType]}`);
  }
  const requestHeaders = {
    Accept: 'application/text'
  };
  const fetchOptions = {
    method: 'GET',
    headers: requestHeaders
  };
  const derivedNetwork = StacksNetwork.fromNameOrNetwork(network ?? deriveNetwork(transaction));
  const url = derivedNetwork.getTransferFeeEstimateApiUrl();
  const response = await derivedNetwork.fetchFn(url, fetchOptions);
  if (!response.ok) {
    const msg = await response.text().catch(() => '');
    throw new Error(`Error estimating contract deploy fee. Response ${response.status}: ${response.statusText}. Attempted to fetch ${url} and failed with the message: "${msg}"`);
  }
  const feeRateResult = await response.text();
  const txBytes = intToBigInt(transaction.serialize().byteLength, false);
  const feeRate = intToBigInt(feeRateResult, false);
  return feeRate * txBytes;
}
export async function makeContractDeploy(txOptions) {
  if ('senderKey' in txOptions) {
    const publicKey = publicKeyToString(getPublicKey(createStacksPrivateKey(txOptions.senderKey)));
    const options = omit(txOptions, 'senderKey');
    const transaction = await makeUnsignedContractDeploy({
      publicKey,
      ...options
    });
    const privKey = createStacksPrivateKey(txOptions.senderKey);
    const signer = new TransactionSigner(transaction);
    signer.signOrigin(privKey);
    return transaction;
  } else {
    const options = omit(txOptions, 'signerKeys');
    const transaction = await makeUnsignedContractDeploy(options);
    const signer = new TransactionSigner(transaction);
    let pubKeys = txOptions.publicKeys;
    for (const key of txOptions.signerKeys) {
      const pubKey = pubKeyfromPrivKey(key);
      pubKeys = pubKeys.filter(pk => pk !== bytesToHex(pubKey.data));
      signer.signOrigin(createStacksPrivateKey(key));
    }
    for (const key of pubKeys) {
      signer.appendOrigin(publicKeyFromBytes(hexToBytes(key)));
    }
    return transaction;
  }
}
export async function makeUnsignedContractDeploy(txOptions) {
  const defaultOptions = {
    fee: BigInt(0),
    nonce: BigInt(0),
    network: new StacksMainnet(),
    postConditionMode: PostConditionMode.Deny,
    sponsored: false,
    clarityVersion: ClarityVersion.Clarity2
  };
  const options = Object.assign(defaultOptions, txOptions);
  const payload = createSmartContractPayload(options.contractName, options.codeBody, options.clarityVersion);
  let authorization = null;
  let spendingCondition = null;
  if ('publicKey' in options) {
    spendingCondition = createSingleSigSpendingCondition(AddressHashMode.SerializeP2PKH, options.publicKey, options.nonce, options.fee);
  } else {
    spendingCondition = createMultiSigSpendingCondition(AddressHashMode.SerializeP2SH, options.numSignatures, options.publicKeys, options.nonce, options.fee);
  }
  if (options.sponsored) {
    authorization = createSponsoredAuth(spendingCondition);
  } else {
    authorization = createStandardAuth(spendingCondition);
  }
  const network = StacksNetwork.fromNameOrNetwork(options.network);
  const postConditions = [];
  if (options.postConditions && options.postConditions.length > 0) {
    options.postConditions.forEach(postCondition => {
      postConditions.push(postCondition);
    });
  }
  const lpPostConditions = createLPList(postConditions);
  const transaction = new StacksTransaction(network.version, authorization, payload, lpPostConditions, options.postConditionMode, options.anchorMode, network.chainId);
  if (txOptions.fee === undefined || txOptions.fee === null) {
    const fee = await estimateTransactionFeeWithFallback(transaction, network);
    transaction.setFee(fee);
  }
  if (txOptions.nonce === undefined || txOptions.nonce === null) {
    const addressVersion = options.network.version === TransactionVersion.Mainnet ? AddressVersion.MainnetSingleSig : AddressVersion.TestnetSingleSig;
    const senderAddress = c32address(addressVersion, transaction.auth.spendingCondition.signer);
    const txNonce = await getNonce(senderAddress, options.network);
    transaction.setNonce(txNonce);
  }
  return transaction;
}
export async function estimateContractFunctionCall(transaction, network) {
  if (transaction.payload.payloadType !== PayloadType.ContractCall) {
    throw new Error(`Contract call fee estimation only possible with ${PayloadType[PayloadType.ContractCall]} transactions. Invoked with: ${PayloadType[transaction.payload.payloadType]}`);
  }
  const requestHeaders = {
    Accept: 'application/text'
  };
  const fetchOptions = {
    method: 'GET',
    headers: requestHeaders
  };
  const derivedNetwork = StacksNetwork.fromNameOrNetwork(network ?? deriveNetwork(transaction));
  const url = derivedNetwork.getTransferFeeEstimateApiUrl();
  const response = await derivedNetwork.fetchFn(url, fetchOptions);
  if (!response.ok) {
    const msg = await response.text().catch(() => '');
    throw new Error(`Error estimating contract call fee. Response ${response.status}: ${response.statusText}. Attempted to fetch ${url} and failed with the message: "${msg}"`);
  }
  const feeRateResult = await response.text();
  const txBytes = intToBigInt(transaction.serialize().byteLength, false);
  const feeRate = intToBigInt(feeRateResult, false);
  return feeRate * txBytes;
}
export async function makeUnsignedContractCall(txOptions) {
  const defaultOptions = {
    fee: BigInt(0),
    nonce: BigInt(0),
    network: new StacksMainnet(),
    postConditionMode: PostConditionMode.Deny,
    sponsored: false
  };
  const options = Object.assign(defaultOptions, txOptions);
  const payload = createContractCallPayload(options.contractAddress, options.contractName, options.functionName, options.functionArgs);
  if (options?.validateWithAbi) {
    let abi;
    if (typeof options.validateWithAbi === 'boolean') {
      if (options?.network) {
        abi = await getAbi(options.contractAddress, options.contractName, options.network);
      } else {
        throw new Error('Network option must be provided in order to validate with ABI');
      }
    } else {
      abi = options.validateWithAbi;
    }
    validateContractCall(payload, abi);
  }
  let spendingCondition = null;
  let authorization = null;
  if ('publicKey' in options) {
    spendingCondition = createSingleSigSpendingCondition(AddressHashMode.SerializeP2PKH, options.publicKey, options.nonce, options.fee);
  } else {
    spendingCondition = createMultiSigSpendingCondition(AddressHashMode.SerializeP2SH, options.numSignatures, options.publicKeys, options.nonce, options.fee);
  }
  if (options.sponsored) {
    authorization = createSponsoredAuth(spendingCondition);
  } else {
    authorization = createStandardAuth(spendingCondition);
  }
  const network = StacksNetwork.fromNameOrNetwork(options.network);
  const postConditions = [];
  if (options.postConditions && options.postConditions.length > 0) {
    options.postConditions.forEach(postCondition => {
      postConditions.push(postCondition);
    });
  }
  const lpPostConditions = createLPList(postConditions);
  const transaction = new StacksTransaction(network.version, authorization, payload, lpPostConditions, options.postConditionMode, options.anchorMode, network.chainId);
  if (txOptions.fee === undefined || txOptions.fee === null) {
    const fee = await estimateTransactionFeeWithFallback(transaction, network);
    transaction.setFee(fee);
  }
  if (txOptions.nonce === undefined || txOptions.nonce === null) {
    const addressVersion = network.version === TransactionVersion.Mainnet ? AddressVersion.MainnetSingleSig : AddressVersion.TestnetSingleSig;
    const senderAddress = c32address(addressVersion, transaction.auth.spendingCondition.signer);
    const txNonce = await getNonce(senderAddress, network);
    transaction.setNonce(txNonce);
  }
  return transaction;
}
export async function makeContractCall(txOptions) {
  if ('senderKey' in txOptions) {
    const publicKey = publicKeyToString(getPublicKey(createStacksPrivateKey(txOptions.senderKey)));
    const options = omit(txOptions, 'senderKey');
    const transaction = await makeUnsignedContractCall({
      publicKey,
      ...options
    });
    const privKey = createStacksPrivateKey(txOptions.senderKey);
    const signer = new TransactionSigner(transaction);
    signer.signOrigin(privKey);
    return transaction;
  } else {
    const options = omit(txOptions, 'signerKeys');
    const transaction = await makeUnsignedContractCall(options);
    const signer = new TransactionSigner(transaction);
    let pubKeys = txOptions.publicKeys;
    for (const key of txOptions.signerKeys) {
      const pubKey = pubKeyfromPrivKey(key);
      pubKeys = pubKeys.filter(pk => pk !== bytesToHex(pubKey.data));
      signer.signOrigin(createStacksPrivateKey(key));
    }
    for (const key of pubKeys) {
      signer.appendOrigin(publicKeyFromBytes(hexToBytes(key)));
    }
    return transaction;
  }
}
export function makeStandardSTXPostCondition(address, conditionCode, amount) {
  return createSTXPostCondition(createStandardPrincipal(address), conditionCode, amount);
}
export function makeContractSTXPostCondition(address, contractName, conditionCode, amount) {
  return createSTXPostCondition(createContractPrincipal(address, contractName), conditionCode, amount);
}
export function makeStandardFungiblePostCondition(address, conditionCode, amount, assetInfo) {
  return createFungiblePostCondition(createStandardPrincipal(address), conditionCode, amount, assetInfo);
}
export function makeContractFungiblePostCondition(address, contractName, conditionCode, amount, assetInfo) {
  return createFungiblePostCondition(createContractPrincipal(address, contractName), conditionCode, amount, assetInfo);
}
export function makeStandardNonFungiblePostCondition(address, conditionCode, assetInfo, assetId) {
  return createNonFungiblePostCondition(createStandardPrincipal(address), conditionCode, assetInfo, assetId);
}
export function makeContractNonFungiblePostCondition(address, contractName, conditionCode, assetInfo, assetId) {
  return createNonFungiblePostCondition(createContractPrincipal(address, contractName), conditionCode, assetInfo, assetId);
}
export async function callReadOnlyFunction(readOnlyFunctionOptions) {
  const defaultOptions = {
    network: new StacksMainnet()
  };
  const options = Object.assign(defaultOptions, readOnlyFunctionOptions);
  const {
    contractName,
    contractAddress,
    functionName,
    functionArgs,
    senderAddress
  } = options;
  const network = StacksNetwork.fromNameOrNetwork(options.network);
  const url = network.getReadOnlyFunctionCallApiUrl(contractAddress, contractName, functionName);
  const args = functionArgs.map(arg => cvToHex(arg));
  const body = JSON.stringify({
    sender: senderAddress,
    arguments: args
  });
  const response = await network.fetchFn(url, {
    method: 'POST',
    body,
    headers: {
      'Content-Type': 'application/json'
    }
  });
  if (!response.ok) {
    const msg = await response.text().catch(() => '');
    throw new Error(`Error calling read-only function. Response ${response.status}: ${response.statusText}. Attempted to fetch ${url} and failed with the message: "${msg}"`);
  }
  return response.json().then(responseJson => parseReadOnlyResponse(responseJson));
}
export async function getContractMapEntry(getContractMapEntryOptions) {
  const defaultOptions = {
    network: new StacksMainnet()
  };
  const {
    contractAddress,
    contractName,
    mapName,
    mapKey,
    network
  } = Object.assign(defaultOptions, getContractMapEntryOptions);
  const derivedNetwork = StacksNetwork.fromNameOrNetwork(network);
  const url = derivedNetwork.getMapEntryUrl(contractAddress, contractName, mapName);
  const serializedKeyBytes = serializeCV(mapKey);
  const serializedKeyHex = '0x' + bytesToHex(serializedKeyBytes);
  const fetchOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    body: JSON.stringify(serializedKeyHex)
  };
  const response = await derivedNetwork.fetchFn(url, fetchOptions);
  if (!response.ok) {
    const msg = await response.text().catch(() => '');
    throw new Error(`Error fetching map entry for map "${mapName}" in contract "${contractName}" at address ${contractAddress}, using map key "${serializedKeyHex}". Response ${response.status}: ${response.statusText}. Attempted to fetch ${url} and failed with the message: "${msg}"`);
  }
  const responseBody = await response.text();
  const responseJson = JSON.parse(responseBody);
  if (!responseJson.data) {
    throw new Error(`Error fetching map entry for map "${mapName}" in contract "${contractName}" at address ${contractAddress}, using map key "${serializedKeyHex}". Response ${response.status}: ${response.statusText}. Attempted to fetch ${url} and failed with the response: "${responseBody}"`);
  }
  let deserializedCv;
  try {
    deserializedCv = deserializeCV(responseJson.data);
  } catch (error) {
    throw new Error(`Error deserializing Clarity value "${responseJson.data}": ${error}`);
  }
  return deserializedCv;
}
export async function sponsorTransaction(sponsorOptions) {
  const defaultOptions = {
    fee: 0,
    sponsorNonce: 0,
    sponsorAddressHashmode: AddressHashMode.SerializeP2PKH,
    network: sponsorOptions.transaction.version === TransactionVersion.Mainnet ? new StacksMainnet() : new StacksTestnet()
  };
  const options = Object.assign(defaultOptions, sponsorOptions);
  const network = StacksNetwork.fromNameOrNetwork(options.network);
  const sponsorPubKey = pubKeyfromPrivKey(options.sponsorPrivateKey);
  if (sponsorOptions.fee === undefined || sponsorOptions.fee === null) {
    let txFee = 0;
    switch (options.transaction.payload.payloadType) {
      case PayloadType.TokenTransfer:
      case PayloadType.SmartContract:
      case PayloadType.VersionedSmartContract:
      case PayloadType.ContractCall:
        const estimatedLen = estimateTransactionByteLength(options.transaction);
        try {
          txFee = (await estimateTransaction(options.transaction.payload, estimatedLen, network))[1].fee;
        } catch (e) {
          throw e;
        }
        break;
      default:
        throw new Error(`Sponsored transactions not supported for transaction type ${PayloadType[options.transaction.payload.payloadType]}`);
    }
    options.transaction.setFee(txFee);
    options.fee = txFee;
  }
  if (sponsorOptions.sponsorNonce === undefined || sponsorOptions.sponsorNonce === null) {
    const addressVersion = network.version === TransactionVersion.Mainnet ? AddressVersion.MainnetSingleSig : AddressVersion.TestnetSingleSig;
    const senderAddress = publicKeyToAddress(addressVersion, sponsorPubKey);
    const sponsorNonce = await getNonce(senderAddress, network);
    options.sponsorNonce = sponsorNonce;
  }
  const sponsorSpendingCondition = createSingleSigSpendingCondition(options.sponsorAddressHashmode, publicKeyToString(sponsorPubKey), options.sponsorNonce, options.fee);
  options.transaction.setSponsor(sponsorSpendingCondition);
  const privKey = createStacksPrivateKey(options.sponsorPrivateKey);
  const signer = TransactionSigner.createSponsorSigner(options.transaction, sponsorSpendingCondition);
  signer.signSponsor(privKey);
  return signer.transaction;
}
export function estimateTransactionByteLength(transaction) {
  const hashMode = transaction.auth.spendingCondition.hashMode;
  const multiSigHashModes = [AddressHashMode.SerializeP2SH, AddressHashMode.SerializeP2WSH];
  if (multiSigHashModes.includes(hashMode)) {
    const multiSigSpendingCondition = transaction.auth.spendingCondition;
    const existingSignatures = multiSigSpendingCondition.fields.filter(field => field.contents.type === StacksMessageType.MessageSignature).length;
    const totalSignatureLength = (multiSigSpendingCondition.signaturesRequired - existingSignatures) * (RECOVERABLE_ECDSA_SIG_LENGTH_BYTES + 1);
    return transaction.serialize().byteLength + totalSignatureLength;
  } else {
    return transaction.serialize().byteLength;
  }
}
export async function estimateTransactionFeeWithFallback(transaction, network) {
  try {
    const estimatedLen = estimateTransactionByteLength(transaction);
    return (await estimateTransaction(transaction.payload, estimatedLen, network))[1].fee;
  } catch (error) {
    if (error instanceof NoEstimateAvailableError) {
      return await estimateTransferUnsafe(transaction, network);
    }
    throw error;
  }
}
