import { utf8ToBytes } from '@stacks/common';
import { addressToString } from '../../common';
import { createAddress, createLPString } from '../../postcondition-types';
import { ClarityType } from '../constants';
function principalToString(principal) {
  if (principal.type === ClarityType.PrincipalStandard) {
    return addressToString(principal.address);
  } else if (principal.type === ClarityType.PrincipalContract) {
    const address = addressToString(principal.address);
    return `${address}.${principal.contractName.content}`;
  } else {
    throw new Error(`Unexpected principal data: ${JSON.stringify(principal)}`);
  }
}
function principalCV(principal) {
  if (principal.includes('.')) {
    const [address, contractName] = principal.split('.');
    return contractPrincipalCV(address, contractName);
  } else {
    return standardPrincipalCV(principal);
  }
}
function standardPrincipalCV(addressString) {
  const addr = createAddress(addressString);
  return {
    type: ClarityType.PrincipalStandard,
    address: addr
  };
}
function standardPrincipalCVFromAddress(address) {
  return {
    type: ClarityType.PrincipalStandard,
    address
  };
}
function contractPrincipalCV(addressString, contractName) {
  const addr = createAddress(addressString);
  const lengthPrefixedContractName = createLPString(contractName);
  return contractPrincipalCVFromAddress(addr, lengthPrefixedContractName);
}
function contractPrincipalCVFromAddress(address, contractName) {
  if (utf8ToBytes(contractName.content).byteLength >= 128) {
    throw new Error('Contract name must be less than 128 bytes');
  }
  return {
    type: ClarityType.PrincipalContract,
    address,
    contractName
  };
}
function contractPrincipalCVFromStandard(sp, contractName) {
  const lengthPrefixedContractName = createLPString(contractName);
  return {
    type: ClarityType.PrincipalContract,
    address: sp.address,
    contractName: lengthPrefixedContractName
  };
}
export { principalCV, principalToString, standardPrincipalCV, standardPrincipalCVFromAddress, contractPrincipalCV, contractPrincipalCVFromAddress, contractPrincipalCVFromStandard };
