import { DeserializationError } from './errors';
import { PubKeyEncoding, RECOVERABLE_ECDSA_SIG_LENGTH_BYTES, StacksMessageType } from './constants';
import { compressPublicKey, deserializePublicKey, serializePublicKey } from './keys';
import { createMessageSignature } from './common';
import { bytesToHex, concatArray, hexToBytes } from '@stacks/common';
export var AuthFieldType;
(function (AuthFieldType) {
  AuthFieldType[AuthFieldType["PublicKeyCompressed"] = 0] = "PublicKeyCompressed";
  AuthFieldType[AuthFieldType["PublicKeyUncompressed"] = 1] = "PublicKeyUncompressed";
  AuthFieldType[AuthFieldType["SignatureCompressed"] = 2] = "SignatureCompressed";
  AuthFieldType[AuthFieldType["SignatureUncompressed"] = 3] = "SignatureUncompressed";
})(AuthFieldType || (AuthFieldType = {}));
export function deserializeMessageSignature(bytesReader) {
  return createMessageSignature(bytesToHex(bytesReader.readBytes(RECOVERABLE_ECDSA_SIG_LENGTH_BYTES)));
}
export function createTransactionAuthField(pubKeyEncoding, contents) {
  return {
    pubKeyEncoding,
    type: StacksMessageType.TransactionAuthField,
    contents
  };
}
export function deserializeTransactionAuthField(bytesReader) {
  const authFieldType = bytesReader.readUInt8Enum(AuthFieldType, n => {
    throw new DeserializationError(`Could not read ${n} as AuthFieldType`);
  });
  switch (authFieldType) {
    case AuthFieldType.PublicKeyCompressed:
      return createTransactionAuthField(PubKeyEncoding.Compressed, deserializePublicKey(bytesReader));
    case AuthFieldType.PublicKeyUncompressed:
      return createTransactionAuthField(PubKeyEncoding.Uncompressed, deserializePublicKey(bytesReader));
    case AuthFieldType.SignatureCompressed:
      return createTransactionAuthField(PubKeyEncoding.Compressed, deserializeMessageSignature(bytesReader));
    case AuthFieldType.SignatureUncompressed:
      return createTransactionAuthField(PubKeyEncoding.Uncompressed, deserializeMessageSignature(bytesReader));
    default:
      throw new Error(`Unknown auth field type: ${JSON.stringify(authFieldType)}`);
  }
}
export function serializeMessageSignature(messageSignature) {
  return hexToBytes(messageSignature.data);
}
export function serializeTransactionAuthField(field) {
  const bytesArray = [];
  switch (field.contents.type) {
    case StacksMessageType.PublicKey:
      if (field.pubKeyEncoding == PubKeyEncoding.Compressed) {
        bytesArray.push(AuthFieldType.PublicKeyCompressed);
        bytesArray.push(serializePublicKey(field.contents));
      } else {
        bytesArray.push(AuthFieldType.PublicKeyUncompressed);
        bytesArray.push(serializePublicKey(compressPublicKey(field.contents.data)));
      }
      break;
    case StacksMessageType.MessageSignature:
      if (field.pubKeyEncoding == PubKeyEncoding.Compressed) {
        bytesArray.push(AuthFieldType.SignatureCompressed);
      } else {
        bytesArray.push(AuthFieldType.SignatureUncompressed);
      }
      bytesArray.push(serializeMessageSignature(field.contents));
      break;
  }
  return concatArray(bytesArray);
}
