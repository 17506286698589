export var ClarityType;
(function (ClarityType) {
  ClarityType[ClarityType["Int"] = 0] = "Int";
  ClarityType[ClarityType["UInt"] = 1] = "UInt";
  ClarityType[ClarityType["Buffer"] = 2] = "Buffer";
  ClarityType[ClarityType["BoolTrue"] = 3] = "BoolTrue";
  ClarityType[ClarityType["BoolFalse"] = 4] = "BoolFalse";
  ClarityType[ClarityType["PrincipalStandard"] = 5] = "PrincipalStandard";
  ClarityType[ClarityType["PrincipalContract"] = 6] = "PrincipalContract";
  ClarityType[ClarityType["ResponseOk"] = 7] = "ResponseOk";
  ClarityType[ClarityType["ResponseErr"] = 8] = "ResponseErr";
  ClarityType[ClarityType["OptionalNone"] = 9] = "OptionalNone";
  ClarityType[ClarityType["OptionalSome"] = 10] = "OptionalSome";
  ClarityType[ClarityType["List"] = 11] = "List";
  ClarityType[ClarityType["Tuple"] = 12] = "Tuple";
  ClarityType[ClarityType["StringASCII"] = 13] = "StringASCII";
  ClarityType[ClarityType["StringUTF8"] = 14] = "StringUTF8";
})(ClarityType || (ClarityType = {}));
