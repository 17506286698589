import { ClarityType, intCV, uintCV, bufferCV, trueCV, falseCV, standardPrincipalCVFromAddress, contractPrincipalCVFromAddress, responseOkCV, responseErrorCV, noneCV, someCV, listCV, tupleCV } from '.';
import { BytesReader } from '../bytesReader';
import { deserializeAddress, deserializeLPString } from '../types';
import { DeserializationError } from '../errors';
import { stringAsciiCV, stringUtf8CV } from './types/stringCV';
import { bytesToAscii, bytesToUtf8, hexToBytes } from '@stacks/common';
export default function deserializeCV(serializedClarityValue) {
  let bytesReader;
  if (typeof serializedClarityValue === 'string') {
    const hasHexPrefix = serializedClarityValue.slice(0, 2).toLowerCase() === '0x';
    bytesReader = new BytesReader(hexToBytes(hasHexPrefix ? serializedClarityValue.slice(2) : serializedClarityValue));
  } else if (serializedClarityValue instanceof Uint8Array) {
    bytesReader = new BytesReader(serializedClarityValue);
  } else {
    bytesReader = serializedClarityValue;
  }
  const type = bytesReader.readUInt8Enum(ClarityType, n => {
    throw new DeserializationError(`Cannot recognize Clarity Type: ${n}`);
  });
  switch (type) {
    case ClarityType.Int:
      return intCV(bytesReader.readBytes(16));
    case ClarityType.UInt:
      return uintCV(bytesReader.readBytes(16));
    case ClarityType.Buffer:
      const bufferLength = bytesReader.readUInt32BE();
      return bufferCV(bytesReader.readBytes(bufferLength));
    case ClarityType.BoolTrue:
      return trueCV();
    case ClarityType.BoolFalse:
      return falseCV();
    case ClarityType.PrincipalStandard:
      const sAddress = deserializeAddress(bytesReader);
      return standardPrincipalCVFromAddress(sAddress);
    case ClarityType.PrincipalContract:
      const cAddress = deserializeAddress(bytesReader);
      const contractName = deserializeLPString(bytesReader);
      return contractPrincipalCVFromAddress(cAddress, contractName);
    case ClarityType.ResponseOk:
      return responseOkCV(deserializeCV(bytesReader));
    case ClarityType.ResponseErr:
      return responseErrorCV(deserializeCV(bytesReader));
    case ClarityType.OptionalNone:
      return noneCV();
    case ClarityType.OptionalSome:
      return someCV(deserializeCV(bytesReader));
    case ClarityType.List:
      const listLength = bytesReader.readUInt32BE();
      const listContents = [];
      for (let i = 0; i < listLength; i++) {
        listContents.push(deserializeCV(bytesReader));
      }
      return listCV(listContents);
    case ClarityType.Tuple:
      const tupleLength = bytesReader.readUInt32BE();
      const tupleContents = {};
      for (let i = 0; i < tupleLength; i++) {
        const clarityName = deserializeLPString(bytesReader).content;
        if (clarityName === undefined) {
          throw new DeserializationError('"content" is undefined');
        }
        tupleContents[clarityName] = deserializeCV(bytesReader);
      }
      return tupleCV(tupleContents);
    case ClarityType.StringASCII:
      const asciiStrLen = bytesReader.readUInt32BE();
      const asciiStr = bytesToAscii(bytesReader.readBytes(asciiStrLen));
      return stringAsciiCV(asciiStr);
    case ClarityType.StringUTF8:
      const utf8StrLen = bytesReader.readUInt32BE();
      const utf8Str = bytesToUtf8(bytesReader.readBytes(utf8StrLen));
      return stringUtf8CV(utf8Str);
    default:
      throw new DeserializationError('Unable to deserialize Clarity Value from Uint8Array. Could not find valid Clarity Type.');
  }
}
