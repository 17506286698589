export function isSubtleCryptoAvailable() {
  return typeof crypto !== 'undefined' && typeof crypto.subtle !== 'undefined';
}
export function isNodeCryptoAvailable(withFeature) {
  try {
    const resolvedResult = require.resolve('crypto');
    if (!resolvedResult) {
      return false;
    }
    const cryptoModule = require('crypto');
    if (!cryptoModule) {
      return false;
    }
    if (withFeature) {
      const features = withFeature(cryptoModule);
      return features;
    }
    return true;
  } catch (error) {
    return false;
  }
}
export const NO_CRYPTO_LIB = 'Crypto lib not found. Either the WebCrypto "crypto.subtle" or Node.js "crypto" module must be available.';
export async function getCryptoLib() {
  if (isSubtleCryptoAvailable()) {
    return {
      lib: crypto.subtle,
      name: 'subtleCrypto'
    };
  } else {
    try {
      const nodeCrypto = require('crypto');
      return {
        lib: nodeCrypto,
        name: 'nodeCrypto'
      };
    } catch (error) {
      throw new Error(NO_CRYPTO_LIB);
    }
  }
}
