import { SessionData } from './sessionData';
import { LOCALSTORAGE_SESSION_KEY } from './constants';
import { NoSessionDataError } from '@stacks/common';
export class SessionDataStore {
  constructor(sessionOptions) {
    if (sessionOptions) {
      const newSessionData = new SessionData(sessionOptions);
      this.setSessionData(newSessionData);
    }
  }
  getSessionData() {
    throw new Error('Abstract class');
  }
  setSessionData(_session) {
    throw new Error('Abstract class');
  }
  deleteSessionData() {
    throw new Error('Abstract class');
  }
}
export class InstanceDataStore extends SessionDataStore {
  constructor(sessionOptions) {
    super(sessionOptions);
    if (!this.sessionData) {
      this.setSessionData(new SessionData({}));
    }
  }
  getSessionData() {
    if (!this.sessionData) {
      throw new NoSessionDataError('No session data was found.');
    }
    return this.sessionData;
  }
  setSessionData(session) {
    this.sessionData = session;
    return true;
  }
  deleteSessionData() {
    this.setSessionData(new SessionData({}));
    return true;
  }
}
export class LocalStorageStore extends SessionDataStore {
  constructor(sessionOptions) {
    super(sessionOptions);
    if (sessionOptions && sessionOptions.storeOptions && sessionOptions.storeOptions.localStorageKey && typeof sessionOptions.storeOptions.localStorageKey === 'string') {
      this.key = sessionOptions.storeOptions.localStorageKey;
    } else {
      this.key = LOCALSTORAGE_SESSION_KEY;
    }
    const data = localStorage.getItem(this.key);
    if (!data) {
      const sessionData = new SessionData({});
      this.setSessionData(sessionData);
    }
  }
  getSessionData() {
    const data = localStorage.getItem(this.key);
    if (!data) {
      throw new NoSessionDataError('No session data was found in localStorage');
    }
    const dataJSON = JSON.parse(data);
    return SessionData.fromJSON(dataJSON);
  }
  setSessionData(session) {
    localStorage.setItem(this.key, session.toString());
    return true;
  }
  deleteSessionData() {
    localStorage.removeItem(this.key);
    this.setSessionData(new SessionData({}));
    return true;
  }
}
