/*
 * From https://github.com/wzbg/base58check
 * @Author: zyc
 * @Date:   2016-09-11 23:36:05
 */
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.decode = exports.encode = void 0;
const sha256_1 = require("@noble/hashes/sha256");
const utils_1 = require("@noble/hashes/utils");
const basex = require("base-x");
const ALPHABET = '123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz';
function encode(data, prefix = '00') {
  const dataBytes = typeof data === 'string' ? (0, utils_1.hexToBytes)(data) : data;
  const prefixBytes = typeof prefix === 'string' ? (0, utils_1.hexToBytes)(prefix) : data;
  if (!(dataBytes instanceof Uint8Array) || !(prefixBytes instanceof Uint8Array)) {
    throw new TypeError('Argument must be of type Uint8Array or string');
  }
  const checksum = (0, sha256_1.sha256)((0, sha256_1.sha256)(new Uint8Array([...prefixBytes, ...dataBytes])));
  return basex(ALPHABET).encode([...prefixBytes, ...dataBytes, ...checksum.slice(0, 4)]);
}
exports.encode = encode;
function decode(string) {
  const bytes = basex(ALPHABET).decode(string);
  const prefixBytes = bytes.slice(0, 1);
  const dataBytes = bytes.slice(1, -4);
  // todo: for better performance replace spread with `concatBytes` method
  const checksum = (0, sha256_1.sha256)((0, sha256_1.sha256)(new Uint8Array([...prefixBytes, ...dataBytes])));
  bytes.slice(-4).forEach((check, index) => {
    if (check !== checksum[index]) {
      throw new Error('Invalid checksum');
    }
  });
  return {
    prefix: prefixBytes,
    data: dataBytes
  };
}
exports.decode = decode;