import { hmac } from '@noble/hashes/hmac';
import { sha256 } from '@noble/hashes/sha256';
import { getPublicKey as nobleGetPublicKey, signSync, utils } from '@noble/secp256k1';
import { bytesToHex, concatBytes, hexToBytes, privateKeyToBytes, PRIVATE_KEY_COMPRESSED_LENGTH, readUInt8 } from '@stacks/common';
import base58 from 'bs58';
import { hashRipemd160 } from './hashRipemd160';
import { hashSha256Sync } from './sha2Hash';
const BITCOIN_PUBKEYHASH = 0x00;
utils.hmacSha256Sync = (key, ...msgs) => {
  const h = hmac.create(sha256, key);
  msgs.forEach(msg => h.update(msg));
  return h.digest();
};
export function makeECPrivateKey() {
  return bytesToHex(utils.randomPrivateKey());
}
export function base58CheckDecode(btcAddress) {
  const bytes = base58.decode(btcAddress);
  const payload = bytes.slice(0, -4);
  const checksum = bytes.slice(-4);
  const newChecksum = sha256(sha256(payload));
  if (checksum[0] ^ newChecksum[0] | checksum[1] ^ newChecksum[1] | checksum[2] ^ newChecksum[2] | checksum[3] ^ newChecksum[3]) {
    throw new Error('Invalid checksum');
  }
  if (payload.length !== 21) throw new TypeError('Invalid address length');
  const version = readUInt8(payload, 0);
  const hash = payload.slice(1);
  return {
    version,
    hash
  };
}
export function base58Encode(hash) {
  const checksum = sha256(sha256(hash));
  return base58.encode(concatBytes(hash, checksum).slice(0, hash.length + 4));
}
export function base58CheckEncode(version, hash) {
  return base58Encode(concatBytes(new Uint8Array([version]), hash.slice(0, 20)));
}
export function publicKeyToBtcAddress(publicKey, version = BITCOIN_PUBKEYHASH) {
  const publicKeyBytes = typeof publicKey === 'string' ? hexToBytes(publicKey) : publicKey;
  const publicKeyHash160 = hashRipemd160(hashSha256Sync(publicKeyBytes));
  return base58CheckEncode(version, publicKeyHash160);
}
export function getPublicKeyFromPrivate(privateKey) {
  const privateKeyBytes = privateKeyToBytes(privateKey);
  return bytesToHex(nobleGetPublicKey(privateKeyBytes.slice(0, 32), true));
}
export function ecSign(messageHash, hexPrivateKey) {
  return signSync(messageHash, privateKeyToBytes(hexPrivateKey).slice(0, 32), {
    der: false
  });
}
export function isValidPrivateKey(privateKey) {
  return utils.isValidPrivateKey(privateKeyToBytes(privateKey));
}
export function compressPrivateKey(privateKey) {
  const privateKeyBytes = privateKeyToBytes(privateKey);
  return privateKeyBytes.length == PRIVATE_KEY_COMPRESSED_LENGTH ? privateKeyBytes : concatBytes(privateKeyBytes, new Uint8Array([1]));
}
