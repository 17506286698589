"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cryptoClients = exports.SECP256K1Client = void 0;
const secp256k1_1 = require("./secp256k1");
Object.defineProperty(exports, "SECP256K1Client", {
  enumerable: true,
  get: function () {
    return secp256k1_1.SECP256K1Client;
  }
});
const cryptoClients = {
  ES256K: secp256k1_1.SECP256K1Client
};
exports.cryptoClients = cryptoClients;
