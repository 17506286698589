import { hmac } from '@noble/hashes/hmac';
import { sha256 } from '@noble/hashes/sha256';
import { getPublicKey, getSharedSecret, Point, Signature, signSync, utils, verify } from '@noble/secp256k1';
import { bigIntToBytes, bytesToHex, bytesToUtf8, concatBytes, FailedDecryptionError, hexToBigInt, hexToBytes, parseRecoverableSignatureVrs, signatureRsvToVrs, utf8ToBytes } from '@stacks/common';
import { fromByteArray, toByteArray } from 'base64-js';
import { createCipher } from './aesCipher';
import { getPublicKeyFromPrivate } from './keys';
import { encodeMessage, hashMessage } from './messageSignature';
import { hashSha256Sync, hashSha512Sync } from './sha2Hash';
import { getAesCbcOutputLength, getBase64OutputLength } from './utils';
utils.hmacSha256Sync = (key, ...msgs) => {
  const h = hmac.create(sha256, key);
  msgs.forEach(msg => h.update(msg));
  return h.digest();
};
export var InvalidPublicKeyReason;
(function (InvalidPublicKeyReason) {
  InvalidPublicKeyReason["InvalidFormat"] = "InvalidFormat";
  InvalidPublicKeyReason["IsNotPoint"] = "IsNotPoint";
})(InvalidPublicKeyReason || (InvalidPublicKeyReason = {}));
export async function aes256CbcEncrypt(iv, key, plaintext) {
  const cipher = await createCipher();
  return await cipher.encrypt('aes-256-cbc', key, iv, plaintext);
}
async function aes256CbcDecrypt(iv, key, ciphertext) {
  const cipher = await createCipher();
  return await cipher.decrypt('aes-256-cbc', key, iv, ciphertext);
}
export function hmacSha256(key, content) {
  return hmac(sha256, key, content);
}
function equalsConstTime(a, b) {
  if (a.length !== b.length) {
    return false;
  }
  let res = 0;
  for (let i = 0; i < a.length; i++) {
    res |= a[i] ^ b[i];
  }
  return res === 0;
}
function sharedSecretToKeys(sharedSecret) {
  const hashedSecret = hashSha512Sync(sharedSecret);
  return {
    encryptionKey: hashedSecret.slice(0, 32),
    hmacKey: hashedSecret.slice(32)
  };
}
function allHexChars(maybe) {
  return maybe.match(/^[0-9a-f]+$/i) !== null;
}
function isValidPublicKey(pub) {
  const invalidFormat = {
    result: false,
    reason_data: 'Invalid public key format',
    reason: InvalidPublicKeyReason.InvalidFormat
  };
  const invalidPoint = {
    result: false,
    reason_data: 'Public key is not a point',
    reason: InvalidPublicKeyReason.IsNotPoint
  };
  if (pub.length !== 66 && pub.length !== 130) return invalidFormat;
  const firstByte = pub.slice(0, 2);
  if (pub.length === 130 && firstByte !== '04') return invalidFormat;
  if (pub.length === 66 && firstByte !== '02' && firstByte !== '03') return invalidFormat;
  if (!allHexChars(pub)) return invalidFormat;
  try {
    const point = Point.fromHex(pub);
    point.assertValidity();
    return {
      result: true,
      reason_data: null,
      reason: null
    };
  } catch (e) {
    return invalidPoint;
  }
}
export function getHexFromBN(bnInput) {
  const hexOut = bnInput.toString(16);
  if (hexOut.length === 64) {
    return hexOut;
  } else if (hexOut.length < 64) {
    const padding = '0'.repeat(64 - hexOut.length);
    return `${padding}${hexOut}`;
  } else {
    throw new Error('Generated a > 32-byte BN for encryption. Failing.');
  }
}
export function getBytesFromBN(bnInput) {
  const result = bigIntToBytes(bnInput, 32);
  if (result.byteLength !== 32) {
    throw new Error('Failed to generate a 32-byte Uint8Array');
  }
  return result;
}
export function getCipherObjectWrapper(opts) {
  const shell = {
    iv: '',
    ephemeralPK: '',
    mac: '',
    cipherText: '',
    wasString: !!opts.wasString
  };
  if (opts.cipherTextEncoding === 'base64') {
    shell.cipherTextEncoding = 'base64';
  }
  const ivLength = 32;
  const ephemeralPKLength = 66;
  const macLength = 64;
  return {
    payloadValuesLength: ivLength + ephemeralPKLength + macLength,
    payloadShell: JSON.stringify(shell)
  };
}
export function getSignedCipherObjectWrapper(payloadShell) {
  const shell = {
    signature: '',
    publicKey: '',
    cipherText: payloadShell
  };
  const signatureLength = 144;
  const publicKeyLength = 66;
  return {
    signedPayloadValuesLength: signatureLength + publicKeyLength,
    signedPayloadShell: JSON.stringify(shell)
  };
}
export function eciesGetJsonStringLength(opts) {
  const {
    payloadShell,
    payloadValuesLength
  } = getCipherObjectWrapper(opts);
  const cipherTextLength = getAesCbcOutputLength(opts.contentLength);
  let encodedCipherTextLength;
  if (!opts.cipherTextEncoding || opts.cipherTextEncoding === 'hex') {
    encodedCipherTextLength = cipherTextLength * 2;
  } else if (opts.cipherTextEncoding === 'base64') {
    encodedCipherTextLength = getBase64OutputLength(cipherTextLength);
  } else {
    throw new Error(`Unexpected cipherTextEncoding "${opts.cipherTextEncoding}"`);
  }
  if (!opts.sign) {
    return payloadShell.length + payloadValuesLength + encodedCipherTextLength;
  } else {
    const {
      signedPayloadShell,
      signedPayloadValuesLength
    } = getSignedCipherObjectWrapper(payloadShell);
    return signedPayloadShell.length + signedPayloadValuesLength + payloadValuesLength + encodedCipherTextLength;
  }
}
export async function encryptECIES(publicKey, content, wasString, cipherTextEncoding) {
  const validity = isValidPublicKey(publicKey);
  if (!validity.result) {
    throw validity;
  }
  const ephemeralPrivateKey = utils.randomPrivateKey();
  const ephemeralPublicKey = getPublicKey(ephemeralPrivateKey, true);
  let sharedSecret = getSharedSecret(ephemeralPrivateKey, publicKey, true);
  sharedSecret = sharedSecret.slice(1);
  const sharedKeys = sharedSecretToKeys(sharedSecret);
  const initializationVector = utils.randomBytes(16);
  const cipherText = await aes256CbcEncrypt(initializationVector, sharedKeys.encryptionKey, content);
  const macData = concatBytes(initializationVector, ephemeralPublicKey, cipherText);
  const mac = hmacSha256(sharedKeys.hmacKey, macData);
  let cipherTextString;
  if (!cipherTextEncoding || cipherTextEncoding === 'hex') {
    cipherTextString = bytesToHex(cipherText);
  } else if (cipherTextEncoding === 'base64') {
    cipherTextString = fromByteArray(cipherText);
  } else {
    throw new Error(`Unexpected cipherTextEncoding "${cipherTextEncoding}"`);
  }
  const result = {
    iv: bytesToHex(initializationVector),
    ephemeralPK: bytesToHex(ephemeralPublicKey),
    cipherText: cipherTextString,
    mac: bytesToHex(mac),
    wasString
  };
  if (cipherTextEncoding && cipherTextEncoding !== 'hex') {
    result.cipherTextEncoding = cipherTextEncoding;
  }
  return result;
}
export async function decryptECIES(privateKey, cipherObject) {
  if (!cipherObject.ephemeralPK) {
    throw new FailedDecryptionError('Unable to get public key from cipher object. ' + 'You might be trying to decrypt an unencrypted object.');
  }
  const ephemeralPK = cipherObject.ephemeralPK;
  let sharedSecret = getSharedSecret(privateKey, ephemeralPK, true);
  sharedSecret = sharedSecret.slice(1);
  const sharedKeys = sharedSecretToKeys(sharedSecret);
  const ivBytes = hexToBytes(cipherObject.iv);
  let cipherTextBytes;
  if (!cipherObject.cipherTextEncoding || cipherObject.cipherTextEncoding === 'hex') {
    cipherTextBytes = hexToBytes(cipherObject.cipherText);
  } else if (cipherObject.cipherTextEncoding === 'base64') {
    cipherTextBytes = toByteArray(cipherObject.cipherText);
  } else {
    throw new Error(`Unexpected cipherTextEncoding "${cipherObject.cipherText}"`);
  }
  const macData = concatBytes(ivBytes, hexToBytes(ephemeralPK), cipherTextBytes);
  const actualMac = hmacSha256(sharedKeys.hmacKey, macData);
  const expectedMac = hexToBytes(cipherObject.mac);
  if (!equalsConstTime(expectedMac, actualMac)) {
    throw new FailedDecryptionError('Decryption failed: failure in MAC check');
  }
  const plainText = await aes256CbcDecrypt(ivBytes, sharedKeys.encryptionKey, cipherTextBytes);
  if (cipherObject.wasString) {
    return bytesToUtf8(plainText);
  }
  return plainText;
}
export function signECDSA(privateKey, content) {
  const contentBytes = typeof content === 'string' ? utf8ToBytes(content) : content;
  const publicKey = getPublicKeyFromPrivate(privateKey);
  const contentHash = hashSha256Sync(contentBytes);
  const signature = signSync(contentHash, privateKey);
  return {
    signature: bytesToHex(signature),
    publicKey
  };
}
export function verifyECDSA(content, publicKey, signature) {
  const contentBytes = typeof content === 'string' ? utf8ToBytes(content) : content;
  const contentHash = hashSha256Sync(contentBytes);
  return verify(signature, contentHash, publicKey, {
    strict: false
  });
}
export function verifyMessageSignature({
  signature,
  message,
  publicKey
}) {
  const {
    r,
    s
  } = parseRecoverableSignatureVrs(signature);
  const sig = new Signature(hexToBigInt(r), hexToBigInt(s));
  const hashedMsg = typeof message === 'string' ? hashMessage(message) : message;
  const verificationResult = verify(sig, hashedMsg, publicKey, {
    strict: false
  });
  if (verificationResult || typeof message !== 'string') return verificationResult;
  const LEGACY_PREFIX = '\x18Stacks Message Signing:\n';
  const legacyHash = sha256(encodeMessage(message, LEGACY_PREFIX));
  return verify(sig, legacyHash, publicKey, {
    strict: false
  });
}
export function verifyMessageSignatureRsv({
  signature,
  message,
  publicKey
}) {
  return verifyMessageSignature({
    signature: signatureRsvToVrs(signature),
    message,
    publicKey
  });
}
