import { makeContractFungiblePostCondition, makeContractNonFungiblePostCondition, makeContractSTXPostCondition, makeStandardFungiblePostCondition, makeStandardNonFungiblePostCondition, makeStandardSTXPostCondition } from './builders';
import { FungibleConditionCode, NonFungibleConditionCode } from './constants';
import { createAssetInfo } from './postcondition-types';
export function principal(principal) {
  if (isContractIdString(principal)) {
    const [address, name] = parseContractId(principal);
    return new PartialPcWithPrincipal(address, name);
  }
  return new PartialPcWithPrincipal(principal, undefined);
}
class PartialPcWithPrincipal {
  constructor(address, contractName) {
    this.address = address;
    this.contractName = contractName;
  }
  willSendEq(amount) {
    return new PartialPcFtWithCode(this.address, amount, FungibleConditionCode.Equal, this.contractName);
  }
  willSendLte(amount) {
    return new PartialPcFtWithCode(this.address, amount, FungibleConditionCode.LessEqual, this.contractName);
  }
  willSendLt(amount) {
    return new PartialPcFtWithCode(this.address, amount, FungibleConditionCode.Less, this.contractName);
  }
  willSendGte(amount) {
    return new PartialPcFtWithCode(this.address, amount, FungibleConditionCode.GreaterEqual, this.contractName);
  }
  willSendGt(amount) {
    return new PartialPcFtWithCode(this.address, amount, FungibleConditionCode.Greater, this.contractName);
  }
  willSendAsset() {
    return new PartialPcNftWithCode(this.address, NonFungibleConditionCode.Sends, this.contractName);
  }
  willNotSendAsset() {
    return new PartialPcNftWithCode(this.address, NonFungibleConditionCode.DoesNotSend, this.contractName);
  }
}
class PartialPcFtWithCode {
  constructor(address, amount, code, contractName) {
    this.address = address;
    this.amount = amount;
    this.code = code;
    this.contractName = contractName;
  }
  ustx() {
    if (this.contractName) {
      return makeContractSTXPostCondition(this.address, this.contractName, this.code, this.amount);
    }
    return makeStandardSTXPostCondition(this.address, this.code, this.amount);
  }
  ft(contractId, tokenName) {
    const [address, name] = parseContractId(contractId);
    if (this.contractName) {
      return makeContractFungiblePostCondition(this.address, this.contractName, this.code, this.amount, createAssetInfo(address, name, tokenName));
    }
    return makeStandardFungiblePostCondition(this.address, this.code, this.amount, createAssetInfo(address, name, tokenName));
  }
}
class PartialPcNftWithCode {
  constructor(principal, code, contractName) {
    this.principal = principal;
    this.code = code;
    this.contractName = contractName;
  }
  nft(...args) {
    const {
      contractAddress,
      contractName,
      tokenName,
      assetId
    } = getNftArgs(...args);
    if (this.contractName) {
      return makeContractNonFungiblePostCondition(this.principal, this.contractName, this.code, createAssetInfo(contractAddress, contractName, tokenName), assetId);
    }
    return makeStandardNonFungiblePostCondition(this.principal, this.code, createAssetInfo(contractAddress, contractName, tokenName), assetId);
  }
}
function parseContractId(contractId) {
  const [address, name] = contractId.split('.');
  if (!address || !name) throw new Error(`Invalid contract identifier: ${contractId}`);
  return [address, name];
}
function parseNft(nftAssetName) {
  const [principal, tokenName] = nftAssetName.split('::');
  if (!principal || !tokenName) throw new Error(`Invalid fully-qualified nft asset name: ${nftAssetName}`);
  const [address, name] = parseContractId(principal);
  return {
    contractAddress: address,
    contractName: name,
    tokenName
  };
}
function isContractIdString(value) {
  return value.includes('.');
}
function getNftArgs(...args) {
  if (args.length === 2) {
    const [assetName, assetId] = args;
    return {
      ...parseNft(assetName),
      assetId
    };
  }
  const [contractId, tokenName, assetId] = args;
  const [address, name] = parseContractId(contractId);
  return {
    contractAddress: address,
    contractName: name,
    tokenName,
    assetId
  };
}
