"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.b58ToC32 = exports.c32ToB58 = exports.versions = exports.c32normalize = exports.c32addressDecode = exports.c32address = exports.c32checkDecode = exports.c32checkEncode = exports.c32decode = exports.c32encode = void 0;
const encoding_1 = require("./encoding");
Object.defineProperty(exports, "c32encode", {
  enumerable: true,
  get: function () {
    return encoding_1.c32encode;
  }
});
Object.defineProperty(exports, "c32decode", {
  enumerable: true,
  get: function () {
    return encoding_1.c32decode;
  }
});
Object.defineProperty(exports, "c32normalize", {
  enumerable: true,
  get: function () {
    return encoding_1.c32normalize;
  }
});
const checksum_1 = require("./checksum");
Object.defineProperty(exports, "c32checkEncode", {
  enumerable: true,
  get: function () {
    return checksum_1.c32checkEncode;
  }
});
Object.defineProperty(exports, "c32checkDecode", {
  enumerable: true,
  get: function () {
    return checksum_1.c32checkDecode;
  }
});
const address_1 = require("./address");
Object.defineProperty(exports, "c32address", {
  enumerable: true,
  get: function () {
    return address_1.c32address;
  }
});
Object.defineProperty(exports, "c32addressDecode", {
  enumerable: true,
  get: function () {
    return address_1.c32addressDecode;
  }
});
Object.defineProperty(exports, "c32ToB58", {
  enumerable: true,
  get: function () {
    return address_1.c32ToB58;
  }
});
Object.defineProperty(exports, "b58ToC32", {
  enumerable: true,
  get: function () {
    return address_1.b58ToC32;
  }
});
Object.defineProperty(exports, "versions", {
  enumerable: true,
  get: function () {
    return address_1.versions;
  }
});