import { MAX_STRING_LENGTH_BYTES, PostConditionPrincipalID, StacksMessageType } from './constants';
import { c32addressDecode } from 'c32check';
import { exceedsMaxLengthBytes } from './utils';
export function parseAssetInfoString(id) {
  const [assetAddress, assetContractName, assetTokenName] = id.split(/\.|::/);
  const assetInfo = createAssetInfo(assetAddress, assetContractName, assetTokenName);
  return assetInfo;
}
export function createLPString(content, lengthPrefixBytes, maxLengthBytes) {
  const prefixLength = lengthPrefixBytes || 1;
  const maxLength = maxLengthBytes || MAX_STRING_LENGTH_BYTES;
  if (exceedsMaxLengthBytes(content, maxLength)) {
    throw new Error(`String length exceeds maximum bytes ${maxLength}`);
  }
  return {
    type: StacksMessageType.LengthPrefixedString,
    content,
    lengthPrefixBytes: prefixLength,
    maxLengthBytes: maxLength
  };
}
export function createAssetInfo(addressString, contractName, assetName) {
  return {
    type: StacksMessageType.AssetInfo,
    address: createAddress(addressString),
    contractName: createLPString(contractName),
    assetName: createLPString(assetName)
  };
}
export function createAddress(c32AddressString) {
  const addressData = c32addressDecode(c32AddressString);
  return {
    type: StacksMessageType.Address,
    version: addressData[0],
    hash160: addressData[1]
  };
}
export function parsePrincipalString(principalString) {
  if (principalString.includes('.')) {
    const [address, contractName] = principalString.split('.');
    return createContractPrincipal(address, contractName);
  } else {
    return createStandardPrincipal(principalString);
  }
}
export function createContractPrincipal(addressString, contractName) {
  const addr = createAddress(addressString);
  const name = createLPString(contractName);
  return {
    type: StacksMessageType.Principal,
    prefix: PostConditionPrincipalID.Contract,
    address: addr,
    contractName: name
  };
}
export function createStandardPrincipal(addressString) {
  const addr = createAddress(addressString);
  return {
    type: StacksMessageType.Principal,
    prefix: PostConditionPrincipalID.Standard,
    address: addr
  };
}
