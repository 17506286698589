"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InvalidTokenError = exports.MissingParametersError = void 0;
class MissingParametersError extends Error {
  constructor(message) {
    super();
    this.name = 'MissingParametersError';
    this.message = message || '';
  }
}
exports.MissingParametersError = MissingParametersError;
class InvalidTokenError extends Error {
  constructor(message) {
    super();
    this.name = 'InvalidTokenError';
    this.message = message || '';
  }
}
exports.InvalidTokenError = InvalidTokenError;
