export const BLOCKSTACK_HANDLER = 'blockstack';
export const BLOCKSTACK_STORAGE_LABEL = 'blockstack';
export const DEFAULT_BLOCKSTACK_HOST = 'https://browser.blockstack.org/auth';
export const DEFAULT_PROFILE = {
  '@type': 'Person',
  '@context': 'http://schema.org'
};
export const DEFAULT_SCOPE = ["store_write"];
export const BLOCKSTACK_APP_PRIVATE_KEY_LABEL = 'blockstack-transit-private-key';
export const DEFAULT_CORE_NODE = 'https://api.hiro.so';
export const NAME_LOOKUP_PATH = '/v1/names';
export const LOCALSTORAGE_SESSION_KEY = 'blockstack-session';
