import { sha256 } from '@noble/hashes/sha256';
import { sha512 } from '@noble/hashes/sha512';
import { getCryptoLib } from './cryptoUtils';
export class NodeCryptoSha2Hash {
  constructor(createHash) {
    this.createHash = createHash;
  }
  async digest(data, algorithm = 'sha256') {
    try {
      const result = this.createHash(algorithm).update(data).digest();
      return Promise.resolve(result);
    } catch (error) {
      console.log(error);
      console.log(`Error performing ${algorithm} digest with Node.js 'crypto.createHash', falling back to JS implementation.`);
      return Promise.resolve(algorithm === 'sha256' ? hashSha256Sync(data) : hashSha512Sync(data));
    }
  }
}
export class WebCryptoSha2Hash {
  constructor(subtleCrypto) {
    this.subtleCrypto = subtleCrypto;
  }
  async digest(data, algorithm = 'sha256') {
    let algo;
    if (algorithm === 'sha256') {
      algo = 'SHA-256';
    } else if (algorithm === 'sha512') {
      algo = 'SHA-512';
    } else {
      throw new Error(`Unsupported hash algorithm ${algorithm}`);
    }
    try {
      const hash = await this.subtleCrypto.digest(algo, data);
      return new Uint8Array(hash);
    } catch (error) {
      console.log(error);
      console.log(`Error performing ${algorithm} digest with WebCrypto, falling back to JS implementation.`);
      return Promise.resolve(algorithm === 'sha256' ? hashSha256Sync(data) : hashSha512Sync(data));
    }
  }
}
export async function createSha2Hash() {
  const cryptoLib = await getCryptoLib();
  if (cryptoLib.name === 'subtleCrypto') {
    return new WebCryptoSha2Hash(cryptoLib.lib);
  } else {
    return new NodeCryptoSha2Hash(cryptoLib.lib.createHash);
  }
}
export function hashSha256Sync(data) {
  return sha256(data);
}
export function hashSha512Sync(data) {
  return sha512(data);
}
