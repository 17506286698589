import { hmac } from '@noble/hashes/hmac';
import { sha256 } from '@noble/hashes/sha256';
import { getPublicKey as nobleGetPublicKey, Point, Signature, signSync, utils } from '@noble/secp256k1';
import { bytesToHex, concatArray, hexToBigInt, hexToBytes, intToHex, parseRecoverableSignatureVrs, privateKeyToBytes, PRIVATE_KEY_COMPRESSED_LENGTH, signatureRsvToVrs, signatureVrsToRsv } from '@stacks/common';
import { c32address } from 'c32check';
import { addressFromVersionHash, addressHashModeToVersion, addressToString, createMessageSignature } from './common';
import { AddressHashMode, COMPRESSED_PUBKEY_LENGTH_BYTES, PubKeyEncoding, StacksMessageType, TransactionVersion, UNCOMPRESSED_PUBKEY_LENGTH_BYTES } from './constants';
import { hash160, hashP2PKH } from './utils';
utils.hmacSha256Sync = (key, ...msgs) => {
  const h = hmac.create(sha256, key);
  msgs.forEach(msg => h.update(msg));
  return h.digest();
};
export function getAddressFromPrivateKey(privateKey, transactionVersion = TransactionVersion.Mainnet) {
  const pubKey = pubKeyfromPrivKey(privateKey);
  return getAddressFromPublicKey(pubKey.data, transactionVersion);
}
export function getAddressFromPublicKey(publicKey, transactionVersion = TransactionVersion.Mainnet) {
  publicKey = typeof publicKey === 'string' ? publicKey : bytesToHex(publicKey);
  const addrVer = addressHashModeToVersion(AddressHashMode.SerializeP2PKH, transactionVersion);
  const addr = addressFromVersionHash(addrVer, hashP2PKH(hexToBytes(publicKey)));
  const addrString = addressToString(addr);
  return addrString;
}
export function createStacksPublicKey(key) {
  return {
    type: StacksMessageType.PublicKey,
    data: hexToBytes(key)
  };
}
export function publicKeyFromSignatureVrs(messageHash, messageSignature, pubKeyEncoding = PubKeyEncoding.Compressed) {
  const parsedSignature = parseRecoverableSignatureVrs(messageSignature.data);
  const signature = new Signature(hexToBigInt(parsedSignature.r), hexToBigInt(parsedSignature.s));
  const point = Point.fromSignature(messageHash, signature, parsedSignature.recoveryId);
  const compressed = pubKeyEncoding === PubKeyEncoding.Compressed;
  return point.toHex(compressed);
}
export function publicKeyFromSignatureRsv(messageHash, messageSignature, pubKeyEncoding = PubKeyEncoding.Compressed) {
  return publicKeyFromSignatureVrs(messageHash, {
    ...messageSignature,
    data: signatureRsvToVrs(messageSignature.data)
  }, pubKeyEncoding);
}
export function publicKeyFromBytes(data) {
  return {
    type: StacksMessageType.PublicKey,
    data
  };
}
export function isCompressed(key) {
  return !bytesToHex(key.data).startsWith('04');
}
export function publicKeyToString(key) {
  return bytesToHex(key.data);
}
export function serializePublicKey(key) {
  return key.data.slice();
}
export function pubKeyfromPrivKey(privateKey) {
  const privKey = createStacksPrivateKey(privateKey);
  const publicKey = nobleGetPublicKey(privKey.data.slice(0, 32), privKey.compressed);
  return createStacksPublicKey(bytesToHex(publicKey));
}
export function compressPublicKey(publicKey) {
  const hex = typeof publicKey === 'string' ? publicKey : bytesToHex(publicKey);
  const compressed = Point.fromHex(hex).toHex(true);
  return createStacksPublicKey(compressed);
}
export function deserializePublicKey(bytesReader) {
  const fieldId = bytesReader.readUInt8();
  const keyLength = fieldId === 4 ? UNCOMPRESSED_PUBKEY_LENGTH_BYTES : COMPRESSED_PUBKEY_LENGTH_BYTES;
  return publicKeyFromBytes(concatArray([fieldId, bytesReader.readBytes(keyLength)]));
}
export function createStacksPrivateKey(key) {
  const data = privateKeyToBytes(key);
  const compressed = data.length == PRIVATE_KEY_COMPRESSED_LENGTH;
  return {
    data,
    compressed
  };
}
export function makeRandomPrivKey() {
  return createStacksPrivateKey(utils.randomPrivateKey());
}
export function signWithKey(privateKey, messageHash) {
  const [rawSignature, recoveryId] = signSync(messageHash, privateKey.data.slice(0, 32), {
    canonical: true,
    recovered: true
  });
  if (recoveryId == null) {
    throw new Error('No signature recoveryId received');
  }
  const recoveryIdHex = intToHex(recoveryId, 1);
  const recoverableSignatureString = recoveryIdHex + Signature.fromHex(rawSignature).toCompactHex();
  return createMessageSignature(recoverableSignatureString);
}
export function signMessageHashRsv({
  messageHash,
  privateKey
}) {
  const messageSignature = signWithKey(privateKey, messageHash);
  return {
    ...messageSignature,
    data: signatureVrsToRsv(messageSignature.data)
  };
}
export function getPublicKey(privateKey) {
  return pubKeyfromPrivKey(privateKey.data);
}
export function privateKeyToString(privateKey) {
  return bytesToHex(privateKey.data);
}
export function publicKeyToAddress(version, publicKey) {
  return c32address(version, bytesToHex(hash160(publicKey.data)));
}
