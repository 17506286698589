import { ripemd160 } from '@noble/hashes/ripemd160';
import { sha256 } from '@noble/hashes/sha256';
import { sha512_256 } from '@noble/hashes/sha512';
import { utils } from '@noble/secp256k1';
import { bytesToHex, concatArray, concatBytes, utf8ToBytes, with0x } from '@stacks/common';
import { c32addressDecode } from 'c32check';
import lodashCloneDeep from 'lodash.clonedeep';
import { deserializeCV, serializeCV } from './clarity';
export { verify as verifySignature } from '@noble/secp256k1';
export const randomBytes = bytesLength => utils.randomBytes(bytesLength);
export const leftPadHex = hexString => hexString.length % 2 == 0 ? hexString : `0${hexString}`;
export const leftPadHexToLength = (hexString, length) => hexString.padStart(length, '0');
export const rightPadHexToLength = (hexString, length) => hexString.padEnd(length, '0');
export const exceedsMaxLengthBytes = (string, maxLengthBytes) => string ? utf8ToBytes(string).length > maxLengthBytes : false;
export function cloneDeep(obj) {
  return lodashCloneDeep(obj);
}
export function omit(obj, prop) {
  const clone = cloneDeep(obj);
  delete clone[prop];
  return clone;
}
export const hash160 = input => {
  return ripemd160(sha256(input));
};
export const txidFromData = data => {
  return bytesToHex(sha512_256(data));
};
export const txidFromBytes = txidFromData;
export const hashP2PKH = input => {
  return bytesToHex(hash160(input));
};
export const hashP2WPKH = input => {
  const keyHash = hash160(input);
  const redeemScript = concatBytes(new Uint8Array([0]), new Uint8Array([keyHash.length]), keyHash);
  const redeemScriptHash = hash160(redeemScript);
  return bytesToHex(redeemScriptHash);
};
export const hashP2SH = (numSigs, pubKeys) => {
  if (numSigs > 15 || pubKeys.length > 15) {
    throw Error('P2SH multisig address can only contain up to 15 public keys');
  }
  const bytesArray = [];
  bytesArray.push(80 + numSigs);
  pubKeys.forEach(pubKey => {
    bytesArray.push(pubKey.length);
    bytesArray.push(pubKey);
  });
  bytesArray.push(80 + pubKeys.length);
  bytesArray.push(174);
  const redeemScript = concatArray(bytesArray);
  const redeemScriptHash = hash160(redeemScript);
  return bytesToHex(redeemScriptHash);
};
export const hashP2WSH = (numSigs, pubKeys) => {
  if (numSigs > 15 || pubKeys.length > 15) {
    throw Error('P2WSH multisig address can only contain up to 15 public keys');
  }
  const scriptArray = [];
  scriptArray.push(80 + numSigs);
  pubKeys.forEach(pubKey => {
    scriptArray.push(pubKey.length);
    scriptArray.push(pubKey);
  });
  scriptArray.push(80 + pubKeys.length);
  scriptArray.push(174);
  const script = concatArray(scriptArray);
  const digest = sha256(script);
  const bytesArray = [];
  bytesArray.push(0);
  bytesArray.push(digest.length);
  bytesArray.push(digest);
  const redeemScript = concatArray(bytesArray);
  const redeemScriptHash = hash160(redeemScript);
  return bytesToHex(redeemScriptHash);
};
export function isClarityName(name) {
  const regex = /^[a-zA-Z]([a-zA-Z0-9]|[-_!?+<>=/*])*$|^[-+=/*]$|^[<>]=?$/;
  return regex.test(name) && name.length < 128;
}
export function cvToHex(cv) {
  const serialized = serializeCV(cv);
  return `0x${bytesToHex(serialized)}`;
}
export function hexToCV(hex) {
  return deserializeCV(hex);
}
export const parseReadOnlyResponse = response => {
  if (response.okay) return hexToCV(response.result);
  throw new Error(response.cause);
};
export const validateStacksAddress = stacksAddress => {
  try {
    c32addressDecode(stacksAddress);
    return true;
  } catch (e) {
    return false;
  }
};
export const validateTxId = txid => {
  if (txid === 'success') return true;
  const value = with0x(txid).toLowerCase();
  if (value.length !== 66) return false;
  return with0x(BigInt(value).toString(16).padStart(64, '0')) === value;
};
