import { utf8ToBytes } from '@stacks/common';
import { decryptECIES, encryptECIES, signECDSA } from './ec';
import { getPublicKeyFromPrivate } from './keys';
export async function encryptContent(content, options) {
  const opts = Object.assign({}, options);
  let privateKey;
  if (!opts.publicKey) {
    if (!opts.privateKey) {
      throw new Error('Either public key or private key must be supplied for encryption.');
    }
    opts.publicKey = getPublicKeyFromPrivate(opts.privateKey);
  }
  const wasString = typeof opts.wasString === 'boolean' ? opts.wasString : typeof content === 'string';
  const contentBytes = typeof content === 'string' ? utf8ToBytes(content) : content;
  const cipherObject = await encryptECIES(opts.publicKey, contentBytes, wasString, opts.cipherTextEncoding);
  let cipherPayload = JSON.stringify(cipherObject);
  if (opts.sign) {
    if (typeof opts.sign === 'string') {
      privateKey = opts.sign;
    } else if (!privateKey) {
      privateKey = opts.privateKey;
    }
    const signatureObject = signECDSA(privateKey, cipherPayload);
    const signedCipherObject = {
      signature: signatureObject.signature,
      publicKey: signatureObject.publicKey,
      cipherText: cipherPayload
    };
    cipherPayload = JSON.stringify(signedCipherObject);
  }
  return cipherPayload;
}
export function decryptContent(content, options) {
  const opts = Object.assign({}, options);
  if (!opts.privateKey) {
    throw new Error('Private key is required for decryption.');
  }
  try {
    const cipherObject = JSON.parse(content);
    return decryptECIES(opts.privateKey, cipherObject);
  } catch (err) {
    if (err instanceof SyntaxError) {
      throw new Error('Failed to parse encrypted content JSON. The content may not ' + 'be encrypted. If using getFile, try passing { decrypt: false }.');
    } else {
      throw err;
    }
  }
}
