import { bytesToHex, readUInt16BE, readUInt32BE, readUInt8 } from '@stacks/common';
function createEnumChecker(enumVariable) {
  const enumValues = Object.values(enumVariable).filter(v => typeof v === 'number');
  const enumValueSet = new Set(enumValues);
  return value => enumValueSet.has(value);
}
const enumCheckFunctions = new Map();
export function isEnum(enumVariable, value) {
  const checker = enumCheckFunctions.get(enumVariable);
  if (checker !== undefined) {
    return checker(value);
  }
  const newChecker = createEnumChecker(enumVariable);
  enumCheckFunctions.set(enumVariable, newChecker);
  return isEnum(enumVariable, value);
}
export class BytesReader {
  constructor(arr) {
    this.consumed = 0;
    this.source = arr;
  }
  readBytes(length) {
    const view = this.source.subarray(this.consumed, this.consumed + length);
    this.consumed += length;
    return view;
  }
  readUInt32BE() {
    return readUInt32BE(this.readBytes(4), 0);
  }
  readUInt8() {
    return readUInt8(this.readBytes(1), 0);
  }
  readUInt16BE() {
    return readUInt16BE(this.readBytes(2), 0);
  }
  readBigUIntLE(length) {
    const bytes = this.readBytes(length).slice().reverse();
    const hex = bytesToHex(bytes);
    return BigInt(`0x${hex}`);
  }
  readBigUIntBE(length) {
    const bytes = this.readBytes(length);
    const hex = bytesToHex(bytes);
    return BigInt(`0x${hex}`);
  }
  get readOffset() {
    return this.consumed;
  }
  set readOffset(val) {
    this.consumed = val;
  }
  get internalBytes() {
    return this.source;
  }
  readUInt8Enum(enumVariable, invalidEnumErrorFormatter) {
    const num = this.readUInt8();
    if (isEnum(enumVariable, num)) {
      return num;
    }
    throw invalidEnumErrorFormatter(num);
  }
}
