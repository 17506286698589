import { utf8ToBytes } from '@stacks/common';
import { ClarityType } from '../constants';
const bufferCV = buffer => {
  if (buffer.byteLength > 1048576) {
    throw new Error('Cannot construct clarity buffer that is greater than 1MB');
  }
  return {
    type: ClarityType.Buffer,
    buffer
  };
};
const bufferCVFromString = str => bufferCV(utf8ToBytes(str));
export { bufferCV, bufferCVFromString };
